import React from 'react';
import './SideBar.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
// import { useNavigate } from 'react-router-dom';
// import { useDispatch } from "react-redux"
// import { logoutUser } from '../../redux/actions/userAction';
// import pic from '../../images/LOGO Wide PNG.png';
import {Link} from "react-router-dom"
import mantraLogo from "../../assets/MantraLogo.png";

function SideBar() {

    // const navigate = useNavigate();
    // const dispatch = useDispatch()
    // const handleLogout = (e) => {
    //     e.preventDefault();
    //     dispatch(logoutUser())
    //     navigate("/login");
    // }

    return (
        <div>
            <aside className="col-sm-3 flex-grow-sm-1 flex-shrink-1 flex-grow-0 sticky-top pb-sm-0 pb-3 " >
                <div className="border rounded-3 p-1 h-100 sticky-top sidebar background1">
                    <ul className="nav nav-pills flex-sm-column flex-row mb-auto text-truncate">
                        <img className="img1" src={mantraLogo} alt="" srcset="" />
                        <li class='underline'>
                            <Link to={'/dashboard'} className="nav-link px-2 text-truncate">
                                <i className="bi bi-speedometer fs-5 white"></i>
                                <span className="d-none d-sm-inline white">Dashboard</span>
                            </Link>
                        </li>
                        <li class='underline'>
                            <Link to={'/profile'} className="nav-link px-2 text-truncate">
                                <i className="bi bi-people fs-5 white"></i>
                                <span className="d-none d-sm-inline white">Profile</span>
                            </Link>
                        </li>
                        {/* bi bi-card-text fs-5 white */}
                        <li class='underline'>
                            <Link to={'/result'} className="nav-link px-2 text-truncate">
                                <i className=" bi bi-card-text fs-5 white"></i>
                                <span className="d-none d-sm-inline white">Result</span>
                            </Link>
                        </li>
                        {/* <li>
                            <i className="bi bi-box-arrow-right fs-5 white mobile" onClick={handleLogout}></i>
                        </li>
                        <li >  <input type="button"
                            onClick={handleLogout}
                            className='btn btn-primary logout web'
                            value="LOGOUT"
                            style={{ width: "10rem" }}

                        /> </li> */}

                    </ul>

                </div>
            </aside>
        </div>
    )
}

export default SideBar
