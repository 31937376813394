/**
 * 
 * @param {String} time12h 
 * Example: 12:00 AM
 * time = 12:00
 * modifier = AM
 * @returns 
 */
export const convertTime12to24 = (time12h="12:00 AM") => {
  if(time12h === null) time12h = "12:00 AM";
  const [time, modifier] =  time12h.split(" ");

  let [hours, minutes="00", seconds="00"] = time.split(":");

  if (hours === "12") {
    hours = "00";
  }

  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}:${seconds}`;
};
