import React from 'react'

function InfoCard({title,image,description,conditions}) {
  return (
    <div className='card-wrapper' style={{backgroundImage: `url(${image})`}}>
        <div className='card-content'>
            <h3>{title}{conditions && <span title='Terms & Conditions Applied'>*</span>}</h3>
            {description?.map(desc => (<p>{desc}</p>))}
        </div>
    </div>
  )
}

export default InfoCard