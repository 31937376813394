import React from 'react';
import { useEffect } from 'react';
import Sidebar from './Sidebar/Sidebar';
import SingleBlog from './SingleBlog/SingleBlog'
import './SingleBlogComponent.css'

export default function SingleBlogComponent() {
  useEffect(()=> {
    window.scrollTo(0,0);
  },[])
  return (
    <div className="single">
      <SingleBlog />
      <Sidebar />
    </div>
  );
}