import axios from 'axios'
const API = process.env.REACT_APP_NODE_API


// Make Payment with Razorpay (Create Order)
export const makePaymentRazorpayHelper = async (studentId) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
    }
    const body = {
      studentId: studentId,
    }
  
    const { data } = await axios.post(`${API}/private/payment/razorpay/init`, body, config)
    return data
  }
  
  // Success Payment
  export const successPaymentRazorpayHelper = async (
    orderId,
    studentId,
  ) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
    }
    const body = {
      orderId: orderId,
      studentId: studentId,
    }
    const { data } = await axios.post(`${API}/private/payment/razorpay/success`, body, config)
    return data
  }