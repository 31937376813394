import React from 'react';
import './SingleBlog.css';
import {SingleBlogLogic} from "./SingleBlogLogic"
import InnerHTML from 'dangerously-set-html-content'
import {Helmet} from "react-helmet";




export default function SingleBlog() {
  const {
    data,
} = SingleBlogLogic();
  return (
    <>
            <Helmet>
           <meta name="description" content={data && data.body.metaDescription} />
          <meta name="keywords" content={data && data.body.metaKeywords} />
        </Helmet>
      <div className="singlePost">
      <div className="singlePostWrapper">
        <img
          className="singlePostImg" 
          src={data && data.body.featureImage}
          alt=""
        />
        <h1 className="singlePostTitle">
        {data && data.body.title}
          <div className="singlePostEdit">
            <i className="singlePostIcon far fa-edit"></i>
            <i className="singlePostIcon far fa-trash-alt"></i>
          </div>
        </h1>
        <div className="singlePostInfo">
          <span>
            Author:
            <b className="singlePostAuthor">
                MANTRA
            </b>
          </span>
          <span>{new Date(data && data.body.createdAt).toLocaleString()}</span>
          
        </div>
        <div className="singlePostDesc">
          {data && <InnerHTML html={data.body.markdown} />}
        </div>
      </div>
    </div>
    </>
  );
}