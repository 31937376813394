// This file contains the data for the terms and conditions and guidelines

//Terms and conditions
const data = [
    {
        id: 1,
        content: "Submissions that are incomplete won't be accepted. You must fill out every bit of information in every column. Please submit your registration form together with all necessary documentation."
    },
    {
        id: 2,
        content: "The student's mobile number and email address are required, as well as those of their parents or current guardians. The exam results and any other pertinent information will be delivered to the student through SMS, email, or both. Matricula shall not be accountable for the nondelivery of the result or any other pertinent information if the student fails to enter his or her parent's or guardian's current and legitimate cellphone number or email address in the Mantra Form at the time of registration. Pay special attention while inputting your mother's email address or your father's mobile number."
    },
    {
        id: 3,
        content: "A student must meet the following requirements to be eligible for his/her scholarship and opportunity to visit ISRO."
    },
    {
        id: 4,
        content: "At any point company can change its terms and conditions as applicable."
    },
    {
        id: 5,
        content: "In the event if there is any disagreement or conflict between the student(s), parent(s), and the Institute on how to interpret these terms and conditions, non-payment of any claim, or any other conflict arising out of or in connection with the same shall be referred to as \"the same\" under these terms and conditions.The parties shall mutually agree upon a single arbitrator to be chosen.The Arbitration & Conciliation Act requires that the procedures take place in Kolkata, and only Kolkata's courts have the authority to hear the case and/or order enforcement if necessary. It is legal for the parties to appoint a different person to act as an arbitrator by mutual agreement of the parties in the manner aforesaid if the arbitrator to whom the matter is referred declines to act, does not enter the reference for any reason, or if after entering the reference proceedings are abandoned, kept in abeyance, or not proceeded with. If all parties agree, that individual will be permitted to continue the case from the point where his predecessor left it."
    }
]

// trip guidelines
const guidelines = [
    {
        id: 1,
        title: 'Terms and Conditions',
        content: ["Maximum 10 students will be selected in MANTRA 6 to 10 each category >1 (5 in total) 11 and 12>1 for engineering aspirant, 1 for medical aspirant for each class (4 in total) 1 will be chosen randomly from the 2nd position holder in each class."]
    },
    {
        id: 2,
        title: 'Eligibility Criteria',
        content: ["Only the following expenses for the selected student(s) and one parent of each selected student shall be borne by Matricula"]
    },
    {
        id: 3,
        title: 'Train Fare Expenses',
        content: ["Accommodation expenses for a maximum of five days Entry Tickets to Space Centre.",
        "The Documentation and processing of the visa shall be done by the Student(s)/Parent(s).",
        "Matricula will reimburse the cost for the same. Exclusions & Important Guidelines.",
        "Expenses other than Train Fare, Accommodation, etc. such as transportation (taxi, cab), food etc. shall be borne by the stuudent(s)/Parent(s).",
        "No reimbursement is available in case stuudent(s)/Parent(s)do not avail the accommodation facility provided by Matricula Matricula shall not be responsible in case of breach of provisions of all applicableany student(s) and parent(s).",
        "Arrival later than 15 minutes to the reporting time for svcheduled start will result cancellation of your tour."]
    },
    {
        id: 4,
        title: 'Instructions for Visiting ISRO Centre',
        content: ["Everyone 16 years or older is required to have a valid Photo ID during the duration Everyone will follow the head-in-charge and report the same for any issues concerning the trip (Adhaar, School ID,etc).Tour.",
        "Travel would be either by train or airlines to be informed soon. Students must have a medical certificate issued by a practising doctor as the student being safe to travel health-wise.",
        "The entire group must stay together, including bus drivers, chaperones, parents, and students. No one is allowed to stray away from the group."]
    }
]

export  {data, guidelines};

















