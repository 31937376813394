import React from 'react'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
// import pdf from '../../file/dummypdf.pdf'
import Brochure from '../../file/BROCHURE.pdf'
import ques from '../../file/SAMPLE-QUESTION-PAPER.pdf'
import './Download.css'
import downloadsWeb from '../../images/200kb/download.png';
import downloadsMobile from '../../images/downloadsMobile.png'
import Swal from 'sweetalert2';

function Download() {
    const handleClick = () => {
        Swal.fire({
            icon: 'info',
            title: 'Click on the link to download',
        })
    }

    return (
        <>
            <section id="downloads-banner">
                <img alt="Mantra" className="downloads-web-banner"
                    src={downloadsWeb}
                    style={{ width: "100%", borderRadius: "0rem" }} />

                <img alt="Mantra" className="downloads-mobile-banner"
                    src={downloadsMobile}
                    style={{ width: "100%", margin: "0rem 0rem 0rem 0rem", height: "19rem", borderRadius: "0rem" }} />
            </section>
            <div className="container">
                <Card sx={{ width: 360, marginBottom: "2rem" }}>
                    <CardContent sx={{ textAlign: "center" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                            <CloudDownloadIcon fontSize='large' onClick={handleClick} />
                            <Typography variant="body1" color="text.secondary">
                                Download MANTRA 2022 Brochure
                            </Typography>
                        </div>
                        <a className='download-btn' href={Brochure} download="MANTRA-BROCHURE" style={{ color: "black" }}>Download</a>
                        {/* <span  onClick={handleClick} >Coming Soon</span> */}
                    </CardContent>
                </Card>
                <Card sx={{ width: 360, marginBottom: "2rem" }}>
                    <CardContent sx={{ textAlign: "center" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                            <CloudDownloadIcon fontSize='large' onClick={handleClick} />
                            <Typography variant="body1" color="text.secondary">
                                Download MANTRA 2022 Sample Papers
                            </Typography>
                        </div>
                        <a className='download-btn' href={ques} download="SAMPLE-QUESTION-PAPER" style={{ color: "black" }}>Download</a>
                        {/* <span onClick={handleClick}  >Coming Soon</span> */}
                    </CardContent>
                </Card>
                {/* <Card sx={{ width: 360, marginBottom: "2rem" }}>
                    <CardContent sx={{ textAlign: "center" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                            <CloudDownloadIcon fontSize='large' onClick={handleClick} />
                            <Typography variant="body1" color="text.secondary">
                                Download MANTRA 2022 Registration Form
                            </Typography>
                        </div>
                        <span onClick={handleClick}   >Coming Soon</span>
                    </CardContent>
                </Card> */}
            </div>


        </>

    )
}

export default Download