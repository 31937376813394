import React from 'react'
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import ShareIcon from "@mui/icons-material/Share";
import Typography from '@mui/material/Typography';
import { ToastContainer } from 'react-toastify';
import { Stack } from '@mui/material';


function BlogCard({currentElement, index, classes, redirectHandler, copyHandler}) {
  return (
    <Card style={{display:'flex'}}>
      <Stack flexBasis={'80%'} padding='0.6rem' gap={1}>
        <Typography style={{lineHeight:'1.3rem',fontWeight:"600",fontSize:'0.9rem'}} variant="h5" component="h5">{currentElement.title}</Typography>
        <Typography style={{fontSize:'0.8rem'}} variant="body2" color="textSecondary" component="p">{currentElement.description.substring(0,150)+'...'}</Typography>
      </Stack>
      <div style={{width:'40%'}}>
        <img style={{width:'100%',objectFit:'cover',height:'100%',aspectRatio:'auto'}} src={currentElement.featureImage}/>
      </div>
    </Card>
  )
}

export default BlogCard