export const QA = [
    {
        question: `What is the syllabi for these exams as far as particular standards are concerned?`,
        answer: `The syllabi which predominantly occupy your studies will be based on the very topics that you've studied as per your board. You may prepare topics that are already there in your existing syllabus and you may also take help from advanced studies to enhance your knowledge for the exams.`
    },
    {
        question: `Are students studying in classes 6 or 7 are eligible for taking up further courses?`,
        answer: `Once a student of 6th or 7th standard gets enrolled through the form submission process, you'll be eligible for the test. You'll be further notified about upcoming courses in our institution and opt for your choice.`
    },
    {
        question: `What if a 12th pass student wants to get enrolled into courses that are provided by you?`,
        answer: `We're still devising methods and organising such fields for the sake of higher education. Practice tests and mock papers will be available soon and you'll be notified within a very short notice once it's done.`
    },
    {
        question: `Are there any practice tests available for the preparation of this exam?`,
        answer: `Yes, you can find the practice papers and other study materials required for your preparation in the upcoming exam on our website.`
    },
    {
        question: `How will I benefit from this examination?`,
        answer: `On academic fields, you'll be getting an insight into the fundamental modules of various patterns and stay updated as far as your test evaluation is concerned. Furthermore, the rewards we have for you will serve as a motivation and will further boost you in securing higher education.`
    }
]