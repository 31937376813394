import React from 'react'
import pic from '../../images/homeWeb.svg'
import pic1 from '../../images/IMG-20220827-WA0013.jpg'
import Card from '@mui/material/Card';
import './Testimonials.css'

function Testimonials() {

    const videoInfo = [
        {
            title: 'The Legacy of MANTRA | Winners | Testimonials | Matricula',
            source: 'https://www.youtube.com/embed/gOqnGoA2nSs'
        },
    ]

    return (
        <div>
            <section id="banner">
                <img
                    alt="Mantra"
                    className="web-banner"
                    src={pic}
                    style={{
                        margin: "0rem 0rem 0rem 0rem",
                        height: "100%",
                        width: "100%",
                        borderRadius: "0rem",
                        backgroundSize: "100% 100%",
                    }}
                />

                <img
                    alt="Mantra"
                    className="mobile-banner"
                    src={pic1}
                    style={{
                        width: "100%",
                        margin: "0rem 0rem 0rem 0rem",
                        height: "20rem",
                        borderRadius: "0rem",
                    }}
                />
            </section>

            <div className="all-cards">
                {
                    videoInfo.map((video, index) => {
                        return (
                            <Card className="TestimonialsCard"  >
                            <h6>{video.title}</h6>
                                {/* <CardContent > */}
                                   <iframe width="450" height="300" src={video.source}
                                    title="YouTube video player" frameborder="0" 
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                    allowfullscreen></iframe>
                                   
                                {/* </CardContent> */}
                           
                            </Card>
                        )
                }
                    )}

               
            </div>
        </div>
    )
}

export default Testimonials
