import styled from 'styled-components'

export const ForgotPasswordContainer = styled.div`
    width: 100%;
    height: 80vh;
    display: grid;
    place-items: center;
    @media screen and (max-width: 960px) {
        height: 100vh;
    }
`

export const ForgotPasswordBox = styled.div`
    width: 500px;
    height: 310px;
    padding:24px 32px;
    border-radius: 8px;
    box-shadow: 0 0.4rem 1.2rem rgb(0 0 0 / 15%);
    @media screen and (max-width: 960px) {
        width: 100%;
    }
`


export const Header = styled.div`
    width: 100%;
    text-align: left;
    font-size: 3.2rem;
    font-weight: 600;
    color: #000;
    height: 70px;
`


export const Heading = styled.h1`
    font-size: 32px;
    font-weight: 600;
    color: #000;
    margin-bottom: 10px;
`

export const Para = styled.p`
      font-size: 14px;
    line-height: 1.42857;
    font-weight: 400;
    color: gray;
    margin-bottom: 20px;
`

export const NumberInput = styled.input`
     font-size: 18px;
    font-weight: 400;
    background: #fff;
    color: rgba(0,0,0,.9);
    position: relative;
    z-index: 1;
    width: 100%;
    height: 52px;
    padding: 28px 12px 6px;
    border-radius: 4px !important;
    margin-bottom: 20px;
    transition: all .5s;
`


export const ResetPasswordButton = styled.button`
    background: #16375C;
    color: #fff;
    width: 100%;
    height: 52px;
    border: 0;
    border-radius: 2px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    font-family: inherit;
    overflow: hidden;
    outline-width: 2px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition-duration: 167ms;
    transition-property: background-color, box-shadow,color;
    transition-timing-function: cubic-bezier(0,0,.2,1);
    z-index: 0;
    border-radius: 28px !important;
    display: block;
`


export const BackButton = styled.button`
        width: 100%;
        height: 32px;
        color: rgba(0,0,0,.6);
        background-color: transparent;
        line-height: 32px;
        margin: 12px auto 0;
        border: none;
        outline: none;
        cursor: pointer;
`