import React, { useEffect } from "react";
import theme from "./Theme";
import { ThemeProvider } from '@mui/material/styles';
import Home from "./Pages/Home/Home";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Dashbord from "./Components/Dashboard/Dashbord";
import Profile from "./Components/Profile/Profile";
import Result from "./Components/Result/Result";
import Login from "./Components/Login/Login";
import { UserProtection } from "./Layout/RouteProtection";
import Scholarship from "./Components/Scholarships/Scholarship";
import Download from "./Components/Downloads/Download";
import tawkTo from 'tawkto-react'
import Syllabus from "./Components/Syllabus/Syllabus";
import NavigationBar from "./Components/NavigationBar/NavigationBar";
import Resourse from "./Components/ResourseCenter/Resourse";
import Footer from "./Components/Footer/Footer";
// import Footer from "./Components/Footer copy/Footer";
import VideoSolutions from "./Components/VideoSolutions/VideoSolutions";
import Testimonials from "./Components/Testimonials/Testimonials";
import Admin from "./Components/Admin/Admin";
import ForgotPassword from './Components/ForgotPassword/ForgotPassword';
import TermsAndConditions from "./Components/TermsAndConditions/TermsAndConditions";
import Blog from './Pages/BlogPage/Blog';
import SingleBlogComponent from './Components/Single Blog/SingleBlogComponent';
import Maintainence from "./Pages/MaintainencePage/Maintainence";

function App() {
  // Tawk to chatbot
  const tawkToPropertyId = '631b26b437898912e9682a53'
  const tawkToKey = '1gch0udg5'
  useEffect(() => {

    var tawk = new tawkTo(tawkToPropertyId, tawkToKey)

    tawk.onStatusChange((status) => {
      console.log(status)
    })

  }, [])
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <NavigationBar />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/dashboard" element={<UserProtection>< Dashbord /></UserProtection>} />
            <Route exact path="/profile" element={<UserProtection>< Profile /></UserProtection>} />
            <Route exact path="/result" element={<UserProtection><Result /></UserProtection>} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route exact path="/syllabus" element={<Syllabus />} />
            <Route exact path="/resource-center" element={<Resourse />} />
            <Route exact path="/downloads" element={<Download />} />
            <Route exact path="/scholarships-awards" element={<Scholarship />} />
            <Route exact path="/blog" element={<Blog />} />
            <Route exact path="/blog/:blogSlug" element={<SingleBlogComponent />} />
            <Route exact path="/video-solutions" element={<VideoSolutions />} />
            <Route exact path="/testimonials" element={<Testimonials />} />
            <Route exact path="/admin/BLcoB1dYi4BBZkd09UlCbdzAfsfsJGJHarfsdfxcxzc" element={<Admin />} />
            <Route exact path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route exact path="/Refund-Policy" element={<TermsAndConditions />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
