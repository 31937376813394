import React from "react";
import HowToReg from '../../../images/how_to_register.svg'

function HowToRegister() {
  return (
    <div className="info-wrapper padding-default">
      <h2>How To Register ?</h2>
      <img loading="lazy" id="how-to-reg" src={HowToReg}/>
    </div>
  );
}

export default HowToRegister;
