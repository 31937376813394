import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    palette: {
        primary: {
            main: '#16375c'
        }
    },
    background: {
        default: '#fff'
    },
    appBar: {
        display: 'flexGrow',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        flexGrow: 1,
        textAlign: 'center'
    },
    blogsContainer: {
        paddingTop: theme.spacing(3)
    },
    blogTitle: {
        fontWeight: 800,
        paddingBottom: theme.spacing(3)
    },
    card: {
        maxWidth: '23.5rem',
        margin: "0rem 1rem 1rem 0rem"
    },
    media: {
        height: 240
    },
    cardActions: {
        display: 'flex',
        margin: '0 10px',
        justifyContent: 'space-between'
    },
    author: {
        display: 'flex'
    },
    paginationContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    parentGrid:{
        width:'100%',
        height: "100%",
        display: "flex",
        alignItems:'center',
        flexWrap:'wrap'
    },
}))

