/**
 * This file contains the steps for the progress bar
 * @param {string} label - The label for the step
 * @param {string} link - The link for the step
 * @param {number} step - The step number
 * @param {number} percentageRequired - The percentage required to unlock the current step
 */

export const progressBarSteps = [
    {
        label: "Registration",
        link: "",
        step: 1,
        percentageRequired: 0,
    },
    {
        label: "Payment",
        link: "",
        step: 2,
        percentageRequired: 0,
    },
    {
        label: "Video Lessons",
        link: "/video-solutions",
        step: 3,
        percentageRequired: 25,
    },
    {
        label: "Cheat Sheets",
        link: "/resource-center",
        step: 4,
        percentageRequired: 50,
    },
    {
        label: "Mock Tests",
        link: "",
        //link: "/mock-tests",
        step: 5,
        percentageRequired: 75,
    },
]