import { useEffect, useState } from "react";

import Swal from 'sweetalert2';
//import redux
import {SmsVerificationForForgotPassword} from "../../redux/actions/forgotPasswordAction"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";



export const ForgotPasswordLogic = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch(); 
    const { userInfo } = useSelector((state) => state.userSignin)
    const { isAuthenticated, error } = useSelector((state) => state.SmsVerificationForForgotPassword)


    const [number, setNumber] = useState("")

    //  SEND TO HOME PAGE IF ALREADY LOGGED IN

    useEffect(() => {
        if(userInfo){
            navigate('/');
        }
      }, [userInfo,navigate])


// ERROR MESSAGE IF ANYTHING GOES WRONG IN OTP MATCHING , LIKE WRONG OTP
    useEffect(() => {
        if(error){
            Swal.fire(error);
            Swal.fire("Warnings", error, "error");
        }
      }, [error])

// IF EVERTHING GOES WELL THEN REDIRECT USER TO OTP VERIFY PAGE

      useEffect(() => {
          if(isAuthenticated){
            Swal.fire({
                title: 'Are you sure?',
                text: "Your previous password has been given to your phone number through SMS.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Go to Login'
              }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/login');
                }
              })
            
          }
}, [isAuthenticated,navigate])



    function sendPasswordHandler(){
        if(number){
        dispatch(SmsVerificationForForgotPassword(number));
        }
        else{
            Swal.fire("Warnings", "Please provide your mobile number", "error");
        }
    }

    return {
        sendPasswordHandler,
        number,
        setNumber,
    }
}