import axios from 'axios'

import {
    EMAIL_VERIFY_REQUEST,
  EMAIL_VERIFY_SUCCESS,
  EMAIL_VERIFY_FAILED,
  OTP_VERIFY_REQUEST,
  OTP_VERIFY_SUCCESS,
  OTP_VERIFY_FAILED,
  UPDATE_PASSWORD_REQUEST,
UPDATE_PASSWORD_SUCCESS,
 UPDATE_PASSWORD_FAILED,
  } from '../constants/forgotPasswordConstants'


const API =  process.env.REACT_APP_NODE_API



///OTP VERIFY FOR FORGOT PASSWORD ///

export const SmsVerificationForForgotPassword = (number) => async (dispatch) => {
    try {
      dispatch({
        type: EMAIL_VERIFY_REQUEST
      })
  
      const config = {
        'Content-Type': 'application/json'
      }
  
      const body = {
        number
      }
  
      const { data } = await axios.post(`${API}/private/forgot-password/send-sms`, body, config)
  
      dispatch({
        type: EMAIL_VERIFY_SUCCESS,
        payload: data
      })
      
    } catch (error) {
      dispatch({
        type: EMAIL_VERIFY_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }



    ///    OTP VERIFY    ///
export const otpVerificationForForgotPassword = (otp,id) => async (dispatch) => {
    try {
      dispatch({
        type: OTP_VERIFY_REQUEST
      })
  
      const config = {
        'Content-Type': 'application/json'
      }
  
      const body = {
        otp:otp
      }
  
      const { data } = await axios.put(`${API}/users/forgot-password/otp-confirmation/${id}`, body, config)
  
      dispatch({
        type: OTP_VERIFY_SUCCESS,
        payload: data
      })
    } catch (error) {
      dispatch({
        type: OTP_VERIFY_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }



      ///    UPDATE PASSWORD    ///
export const updatePassword = (password,id) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_PASSWORD_REQUEST
    })

    const config = {
      'Content-Type': 'application/json'
    }

    const body = {
      password:password
    }

    const { data } = await axios.put(`${API}/users/forgot-password/updatepassword/${id}`, body, config)

    dispatch({
      type: UPDATE_PASSWORD_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: UPDATE_PASSWORD_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    })
  }
}