import * as React from 'react';
import  { useState } from 'react'
import { login } from '../../redux/actions/userAction'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import moment from 'moment';
import Swal from 'sweetalert2';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
// import Link from '@mui/material/Link';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LoginImage from '../../assets/signImage.webp';




const theme = createTheme();

function Login() {

    const { userInfo, error } = useSelector((state) => (state.userSignin));

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [data, setData] = useState({
        roll: "",
        date: moment().format('YYYY-MM-DD'),
    });

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: [e.target.value] });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (data.roll !== "" || data.date !== "") {
        const userData = {
            rollNo: data.roll.toString(),
            DOB: data.date.toString()
        }

        dispatch(login(userData));
        }
        else {
        Swal.fire({
            icon: 'error',
            title: 'add fields',
        })
        }
    }

    useEffect(() => {
        if (userInfo) {
        Swal.fire({
            icon: 'success',
            title: 'Login successfully',
        })
        navigate("/dashboard")
        }
    }, [userInfo, navigate]);

    useEffect(() => {
        if (error) {
        Swal.fire({
            icon: 'error',
            title: 'Wrong Login credentials',
        })
        }
    }, [error]); 

  return (
    <Container >
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '90vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={false}
          md={7}
          sx={{
            backgroundImage: `url(${LoginImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={12} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: '#16375C' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Log in
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                margin="normal"
                required
                fullWidth
                id="roll-number"
                label="Roll/Mobile Number"
                name="roll"
                autoFocus
                value={data.roll}
                onChange={handleChange}
              />
              <TextField
                onChange={handleChange}
                value={data.date}
                name="date"
                margin="normal"
                required
                fullWidth
                id="dob"
                label=" Date of Birth"
                type="date"
                defaultValue="2017-05-24"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 , bgcolor: '#16375C'}}
              >SUBMIT
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link style={{color:'#16375C'}}to="/forgot-password"variant="body2">
                    {' '}
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  
                  <Link style={{color:'#16375C'}} to="/" variant="body2">
                    
                    {"Not Registered Yet? Register Now"}
              
                  </Link>
                </Grid>
              </Grid>
              
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
    </Container>
  );
}

export default Login
