import axios from 'axios'
import {
GET_ALL_BLOGS_REQUEST,
GET_ALL_BLOGS_SUCCESS,
GET_ALL_BLOGS_FAILED,
GET_SINGLE_BLOG_REQUEST,
GET_SINGLE_BLOG_SUCCESS,
GET_SINGLE_BLOG_FAILED,
} from "../constants/blogConstants"

const API =  process.env.REACT_APP_NODE_API


  // GET ALL BLOGS
  export const getAllBlogs = ( ) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_BLOGS_REQUEST
    })

    const config = {
      'Content-Type': 'application/json'
    }
    const { data } = await axios.get(`${API}/blog/list`, config)
    dispatch({
      type: GET_ALL_BLOGS_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: GET_ALL_BLOGS_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    })
  }
}



  // GET SINGLE  BLOG
  export const getSingleBlog = (slug) => async (dispatch) => {
    try {
      dispatch({
        type: GET_SINGLE_BLOG_REQUEST
      })
  
  
      const config = {
        'Content-Type': 'application/json',
      }
      const { data } = await axios.get(`${API}/blog/get-blog/${slug}`, config)
      
      dispatch({
        type: GET_SINGLE_BLOG_SUCCESS,
        payload: data
      })
    } catch (error) {
      dispatch({
        type: GET_SINGLE_BLOG_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }
