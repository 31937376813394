import { useState } from "react";
import { otpUser, verifyOtp, register } from "../../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Swal from "sweetalert2";
// import { displayRazorpay } from '../../utils/PayRegistrationFee';
import { loadScript } from "../../utils/loadScript";
import {
  makePaymentRazorpayHelper,
  successPaymentRazorpayHelper,
} from "../PaymentCard/helper";

function useRegistrationLogic() {
  // For Registration Exam Dates Dropdown
  var daysOfYear = [];
  const options = { day: "numeric", month: "long", year: "numeric" };
  for (
    var d = new Date(2023, 4, 13);
    d <= new Date(2023, 6, 13);
    d.setDate(d.getDate() + 1)
  ) {
    daysOfYear.push(new Date(d).toLocaleDateString("en-us", options));
  }

  const { REACT_APP_RAZORPAY_ID } = process.env;

  const handleTop = () => {
    window.scrollTo(0, 0);
  };
  const [data, setData] = useState({
    mobile_no: "",
  });

  const [verifyData, setVerifyData] = useState({
    otp: "",
  });

  const {
    data: otpData,
    error,
    loading: otpsentLoading,
  } = useSelector((state) => state.otpReducer);

  const {
    data: verifyotpData,
    error: otpError,
    loading: verifyOtpLoading,
  } = useSelector((state) => state.verifyOtpReducer);
  const { userInfo: registerData, loading: verifyDataLoading } = useSelector(
    (state) => state.userSignup
  );
  const { userInfo: loggedInData } = useSelector((state) => state.userSignin);

  const [show, setShow] = useState(false);

  const [verified, setVerified] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: [e.target.value] });
    // console.log(parseInt(data.mobile_no))
  };

  const handleChange1 = (e) => {
    setVerifyData({ ...verifyData, [e.target.name]: [e.target.value] });
    // console.log(parseInt(verifyData.otp))
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (data.mobile_no !== "") {
      const userData = {
        mobileNumber: parseInt(data.mobile_no),
      };

      dispatch(otpUser(userData));
    } else {
      alert("not validate");
    }
  };

  const handleSubmit1 = (e) => {
    e.preventDefault();
    const userData = {
      mobileNumber: parseInt(data.mobile_no),
      OTP: parseInt(verifyData.otp),
    };
    dispatch(verifyOtp(userData));
  };

  useEffect(() => {
    if (otpData) {
      Swal.fire({
        icon: "success",
        title: "Otp sent successfully",
      });

      setShow(true);
    }
  }, [otpData]);

  useEffect(() => {
    if (registerData && show) {
      Swal.fire({
        icon: "success",
        title: "Registered successfully",
      });
      navigate("/dashboard");
    }
  }, [registerData, navigate, show]);

  useEffect(() => {
    if (verifyotpData) {
      setVerified(true);
    }
  }, [verifyotpData]);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
    }
  }, [error]);

  useEffect(() => {
    if (otpError) {
      Swal.fire({
        icon: "error",
        title: "Wrong otp",
        text: otpError,
      });
    }
  }, [otpError]);

  const [user, setUser] = useState({
    name: "",
    guardian: "",
    email: "",
    date: "",
    class: "",
    mode: "",
    // district: "",
    exam: "",
    homeTown: "",
    state: "",
    referral: "",
  });

  const handleChange2 = (e) => {
    setUser({ ...user, [e.target.name]: [e.target.value] });
    // console.log(user);
  };

  const handleSubmit2 = (e) => {
    e.preventDefault();
    console.log(user.state.toString());
    if (user.exam.toString() === "Booked") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "All seats are booked, for that date",
      });
      return;
    }
    if (
      !user.state.toString() ||
      !user.class.toString() ||
      !user.mode.toString() ||
      !user.exam.toString() ||
      !user.homeTown.toString() ||
      !user.name.toString() ||
      !user.guardian.toString() ||
      !user.email.toString() ||
      !user.date.toString()
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select all the fields",
      });
      return;
    }

    const userData = {
      stundetName: user.name.toString(),
      mobileNumber: parseInt(data.mobile_no),
      studentEmail: user.email.toString(),
      homeTown: user.homeTown.toString(),
      state: user.state.toString(),
      GuardianName: user.guardian.toString(),
      dateOfBirth: user.date.toString(),
      classAndStream: user.class.toString(),
      examModeAndTime: user.mode.toString(),
      referral: user.referral.toString(),
      // District: user.district.toString(),
      ExamDate: user.exam.toString(),
      paymentMethod: "razorpay",
    };


    const dispalyRazorpay = async () => {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }
      // create order of Razorpay from backend and get orderId and amount
      const data = await makePaymentRazorpayHelper(
        otpData && otpData.data.data._id
      );
      const options = {
        key: REACT_APP_RAZORPAY_ID,
        currency: data.currency,
        amount: data.amount,
        order_id: data.orderId,
        name: "Matricula",
        description: "Pay your registration Fee",
        handler: async function () {
          // Payment Success Helper
          const res = await successPaymentRazorpayHelper(
            data.orderId,
            otpData && otpData.data.data._id
          );
          if (res) {
            Swal.fire({
              icon: "success",
              title: `Transaction completed by ${user.name.toString()}  to MANTRA`,
            });
            dispatch(register(userData));
            window.location.reload(true);
          }
        },
        prefill: {
          email: user.email.toString(),
          phone_number: parseInt(data.mobile_no),
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    };

    dispalyRazorpay();
  };


  var daysOfYear = [];
  for (
    var d = new Date(2023, 4, 13);
    d <= new Date(2023, 6, 13);
    d.setDate(d.getDate() + 1)
  ) {
    daysOfYear.push(new Date(d).toLocaleDateString("en-us", { day: "numeric", month: "long", year: "numeric" }));
  }

  return {
    handleChange,
    handleSubmit,
    handleChange1,
    handleSubmit1,
    handleChange2,
    handleSubmit2,
    verified,
    show,
    user,
    data,
    verifyData,
    verifyDataLoading,
    verifyotpData,
    otpData,
    registerData,
    error,
    otpError, 
    loggedInData,
    daysOfYear,
  }
}

export default useRegistrationLogic;