import React from 'react'
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import ShareIcon from "@mui/icons-material/Share";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ToastContainer } from 'react-toastify';
import { Chip } from '@mui/material';


function BlogCard({currentElement, index, classes, redirectHandler, copyHandler}) {
  return (
    <Card className={classes.card}
                key={index}
                >
                  <CardActionArea
                onClick={()=>{redirectHandler(currentElement.slug)}} 
                  >
                    <CardMedia
                      className={classes.media}
                      image={currentElement.featureImage}
                      title="blog"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        {currentElement.title.substring(0,25)+"..."}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        {currentElement.description.substring(0, 150)+"..."}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions className={classes.cardActions}>
                    <Box className={classes.author}>
                    <Avatar aria-label="recipe" style={{background:"#16375C"}}>
                M
              </Avatar>
                      <Box>
                        <Typography color='textSecondary' variant="subtitle2" component="p">
                          By {currentElement.author}, {new Date(currentElement.createdAt).toLocaleString()}
                        </Typography>
                        
                      </Box>
                    </Box>
                    <Box onClick={()=>{copyHandler(currentElement.slug)}}>
                      <ShareIcon />
                      <ToastContainer />
                    </Box>
                  </CardActions>
                </Card>
  )
}

export default BlogCard