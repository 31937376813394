import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom'


export const UserProtection = ({ children }) => {
    const { userInfo } = useSelector((state) => state.userSignin)
    const { userInfo:RegData } = useSelector((state) => state.userSignup)

    const userData = userInfo || RegData

    // console.log(userData);

    if (!userData) {
        return <Navigate to="/"/>;
      }
    
      return children;
}