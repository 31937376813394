import React from 'react';
import { Link } from 'react-router-dom';
import "./Sidebar.css";
import { useStyles } from '../../../Pages/BlogPage/Blog.style';
import { Stack } from '@mui/material';
import BlogCard from '../../BlogCard/SideBlogCard';
import {BlogLogic} from "../../../Pages/BlogPage/BlogLogic"

export default function Sidebar() {
  const classes = useStyles();
  const {
    blogs,
    redirectHandler,
    copyHandler
} = BlogLogic();
  console.log(blogs);

  return (
    <div className="sidebar">
      <h4 style={{textAlign:'left',width:'100%',paddingBottom:'0.5rem',borderBottom:'1px solid black'}}>Recent Blogs</h4>
      <div className="sidebarItem">
        <Stack borderTop direction={'column'}>
          {blogs && blogs.body.slice(0.8).map((blog,index) => 
            <BlogCard index={index} currentElement={blog} classes={classes} copyHandler={copyHandler} redirectHandler={redirectHandler}/>
          )}
        </Stack>
      </div>
      {/* <Link to="/login" style={{background: "#16375c", color: "#fff", padding: ".5rem 1rem", textDecoration: "none", borderRadius: "2rem",marginLeft:'2rem'}}>
      <span className="">Go To Dashboard</span>
      </Link> */}
    </div>
  );
}