  ///    CREATE BLOG  REDUCER    ///
  import {
    GET_ALL_BLOGS_REQUEST,
    GET_ALL_BLOGS_SUCCESS,
    GET_ALL_BLOGS_FAILED,
    GET_SINGLE_BLOG_REQUEST,
    GET_SINGLE_BLOG_SUCCESS,
    GET_SINGLE_BLOG_FAILED
    } from '../constants/blogConstants'


  const getAllBlogsInitialState = {
    loading: false,
    error: null,
    isAuthenticated:false,
    blogs:null
    }
export const getAllBlogsReducer = (
    state = getAllBlogsInitialState,
    action
  ) => {
    switch (action.type) {
      case GET_ALL_BLOGS_REQUEST:
        return { ...state, loading: true}
      case GET_ALL_BLOGS_SUCCESS:
        return {
            ...state,
          loading: false,
          isAuthenticated: true,
          blogs: action.payload
        }
      case GET_ALL_BLOGS_FAILED:
        return {
            ...state,
          loading: false,
          isAuthenticated:false,
          error: action.payload,
          blogs:null
        }
      default:
        return state
    }
  }



  
  const getSingleBlogInitialState = {
    loading: false,
    error: null,
    isAuthenticated:false,
    data:null
    }
export const getSingleBlogReducer = (
    state = getSingleBlogInitialState,
    action
  ) => {
    switch (action.type) {
      case GET_SINGLE_BLOG_REQUEST:
        return {
           ...state,
           loading: true,
           error: null,
           isAuthenticated:false,
           data:null
        }
      case GET_SINGLE_BLOG_SUCCESS:
        console.log(action)
        return {
            ...state,
            loading: false,
            error: null,
            isAuthenticated:true,
          data: action.payload
        }
      case GET_SINGLE_BLOG_FAILED:
        return {
            ...state,
          loading: false,
          isAuthenticated:false,
          error: action.payload,
          data:null
        }
      default:
        return state
    }
  }
