import React, { useEffect } from "react";
import pic from "../../images/homeWeb.svg";
import pic1 from "../../images/IMG-20220827-WA0013.jpg";
import Card from "@mui/material/Card";
import "./VideoSolutions.css";
import { updateStudentActivityStatus } from "../../data/axiosCalls/updateStudentActivityStatus";
import { useDispatch, useSelector } from "react-redux";
import { userDetails } from "../../redux/actions/userAction";

function VideoSolutions() {
const { data: user } = useSelector((state) => state.userDetailsReducer);
const dispatch = useDispatch()

useEffect(() => {
  dispatch(userDetails());
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

useEffect(() => {
    updateStudentActivityStatus(user, {videoSolutionStatus: true})
  }, [user])

  const videoInfo = [
    {
      title: "MANTRA- 2022",
      source: "https://www.youtube.com/embed/ouEWFo52ykA",
    },
    {
      title: "What is MANTRA? I MANTRA- 2022 | Class - VI to XII",
      source: "https://www.youtube.com/embed/HqYgBCnFkOg",
    },
    {
      title:
        "All about MANTRA- 2022 | Syllabus,Eligibility Criteria,Exam Pattern | Class - XI & XII",
      source: "https://www.youtube.com/embed/vlIq9ToE0Ro",
    },
    {
      title:
        "All about MANTRA- 2022 | Syllabus,Eligibility Criteria,Exam Pattern | Class -VI to VIII",
      source: "https://www.youtube.com/embed/iax78aMpz8U",
    },
    {
      title:
        "All about MANTRA- 2022 | Syllabus,Eligibility Criteria,Exam Pattern | Class - IX & X",
      source: "https://www.youtube.com/embed/fAfyUW8WrUo",
    },
    {
      title:
        "All about MANTRA- 2022 | Syllabus,Eligibility Criteria,Exam Pattern | Class - VI to XII | Hindi",
      source: "https://www.youtube.com/embed/ygAhiHppxBA",
    },
    {
      title: "MANTRA | Class VI Syllabus Explained",
      source: "https://www.youtube.com/embed/qTm-pgdM_68",
    },
    {
      title: "MANTRA | Class VII Syllabus Explained",
      source: "https://www.youtube.com/embed/8Q3Ww3ZfeNo",
    },
    {
      title: "MANTRA | Class VIII Syllabus Explained",
      source: "https://www.youtube.com/embed/Inytqc9lUIY",
    },
    {
      title: "MANTRA | Class IX Syllabus Explained",
      source: "https://www.youtube.com/embed/MmJ3AdRgY20",
    },
    {
      title: "MANTRA | Class X (Engineering) Syllabus Explained",
      source: "https://www.youtube.com/embed/_BZLouZ3jk4",
    },
    {
      title: "MANTRA | Class X (Medical) Syllabus Explained",
      source: "https://www.youtube.com/embed/5FqdbPvz2Ns",
    },
    {
      title: "MANTRA | Class XI (Engineering) Syllabus Explained",
      source: "https://www.youtube.com/embed/lUK1NtgjkuI",
    },
    {
      title: "MANTRA | Class XI (Medical) Syllabus Explained",
      source: "https://www.youtube.com/embed/PvUDSn0uYuM",
    },
    {
      title: "MANTRA | Class XII (Engineering) Syllabus Explained",
      source: "https://www.youtube.com/embed/oRKH9HvtV6M",
    },
    {
      title: "MANTRA | Class XII (Medical) Syllabus Explained",
      source: "https://www.youtube.com/embed/0DRL5TyTYw0",
    },
    {
      title: "MANTRA | Class VI to VIII Exam Pattern",
      source: "https://www.youtube.com/embed/fmqbRsAcef0",
    },
    {
      title: "MANTRA | Class IX Exam Pattern",
      source: "https://www.youtube.com/embed/dt3rFXknt9Y",
    },
    {
      title: "MANTRA | Class X (Engineering) Exam Pattern",
      source: "https://www.youtube.com/embed/POth7HdgmS0",
    },
    {
      title: "MANTRA | Class X (Medical) Exam Pattern",
      source: "https://www.youtube.com/embed/BQptNohaVmg",
    },
    {
      title: "MANTRA | Class XI & XII (Engineering) Exam Pattern",
      source: "https://www.youtube.com/embed/YBIvruw2eT4",
    },
    {
      title: "MANTRA | Class XI & XII (Medical) Exam Pattern",
      source: "https://www.youtube.com/embed/j9rpayJ-o9Y",
    },
  ];
 

  return (
    <div>
      <section id="banner">
        <img
          alt="Mantra"
          className="web-banner"
          src={pic}
          style={{
            margin: "0rem 0rem 0rem 0rem",
            height: "100%",
            width: "100%",
            borderRadius: "0rem",
            backgroundSize: "100% 100%",
          }}
        />

        <img
          alt="Mantra"
          className="mobile-banner"
          src={pic1}
          style={{
            width: "100%",
            margin: "0rem 0rem 0rem 0rem",
            height: "20rem",
            borderRadius: "0rem",
          }}
        />
      </section>

      <div className="all-cards">
        {videoInfo.map((video, index) => {
          return (
            <Card className="TestimonialsCard">
              <h6>{video.title}</h6>
              <iframe
                width="450"
                height="300"
                src={video.source}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                loading="lazy"
                controls='2'
                
              ></iframe>
            </Card>
          );
        })}
      </div>
    </div>
  );
}

export default VideoSolutions;
