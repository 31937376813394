import React, { useEffect } from 'react'
import './TermsAndConditions.css'
import {data,guidelines} from '../../Typography/TermsAndConditionsData'
import RefundPolicy from '../RefundPolicy/RefundPolicy'
import { useLocation } from 'react-router-dom'

function TermsAndConditions() {
    const {pathname} = useLocation()

    useEffect(() => {
       if(pathname === '/terms-and-conditions') window.scrollTo(0, 0)
       else{
            document.getElementById('refund').scrollIntoView()
       }
    }, [pathname])

  return (
    <div className='flex-col-center'>
        <h1>Terms And Conditions</h1>
        <ol className='list'>
            {data.map((item, index) => {
                return <li className='term' key={index}>{item.content}</li>
            })
            }
        </ol>
        <h1>Guildlines For The Trip</h1>
        {guidelines.map((item, index) => {
            return (
                <div className='' key={item.id}>
                    <h4>{item.title}</h4>
                    <ul className='list'>
                        {item.content.map((item, index) => {
                            return <li className='term' key={index}>{item}</li>
                        })
                        }
                    </ul>
                </div>
            )
        })}
        <RefundPolicy />
    </div>
  )
}

export default TermsAndConditions