import Twitter from "../../images/Group 262.svg";
import Facebook from "../../images/Group 263.svg";
import Instagram from "../../images/Group 264.svg";
import YouTube from "../../images/Group 265.svg";

export const socials = [
  {
    name: "Facebook",
    link: "https://www.facebook.com/matricula.edu/",
    icon: Facebook,
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/matricula_edu/",
    icon: Instagram,
  },
  {
    name: "Twitter",
    link: "https://twitter.com/Matricula_edu",
    icon: Twitter,
  },
  {
    name: "YouTube",
    link: "https://www.youtube.com/channel/UCcFrPZj7FXWIlWb7ZCLao2Q",
    icon: YouTube,
  },
];

export const footerCol3 = [
  {
    name: "News & Events",
    link: "https://matricula.co.in/news-and-events/",
    isOtherSite: true,
  },
  {
    name: "Announcements",
    link: "https://matricula.co.in/announcements/",
    isOtherSite: true,
  },
  {
    name: "About Us",
    link: "https://www.matricula.co.in/about-us",
    isOtherSite: true,
  },
  {
    name: "Contact Us",
    link: "https://matricula.co.in/contact-us/",
    isOtherSite: true,
  },
  {
    name: "Team",
    link: "https://www.matricula.co.in/about-us",
    isOtherSite: true,
  },
  {
    name: "Visual Stories",
    link: "https://matricula.co.in/visual-stories/",
    isOtherSite: true,
  },
];

export const footerCol4 = [
  {
    name: "Terms & Conditions",
    link: "https://www.matricula.co.in/terms-and-conditions",
    isOtherSite: true,
  },
  {
    name: "Privacy Policy",
    link: "https://www.matricula.co.in/privacy-policy",
    isOtherSite: true,
  },
  {
    name: "Refund Policy",
    link: "https://www.matricula.co.in/terms-and-conditions",
    isOtherSite: true,
  },
  {
    name: "Affiliate Disclosure",
    link: "https://matricula.co.in/affiliate-disclosure/",
    isOtherSite: true,
  },
  {
    name: "Disclaimer",
    link: "https://matricula.co.in/disclaimer/",
    isOtherSite: true,
  },
  {
    name: "Career",
    link: "https://www.linkedin.com/company/matricula/",
    isOtherSite: true,
  },
];

export const footerCol1 = [
  {
    name: "MATRICULA",
    link: "https://matricula.co.in/",
    isOtherSite: true,
  },
  {
    name: "MTS",
    link: "https://mts.matricula.co.in/",
    isOtherSite: true,
  },
  {
    name: "Spoken English",
    link: "https://spokenenglish.matricula.co.in/",
    isOtherSite: true,
  },
  {
    name: "Courses",
    link: "https://courses.matricula.co.in/",
    isOtherSite: true,
  },
];

export const footerCol2 = [
  {
    name: "Student's Corner",
    link: "https://matricula.co.in/students-corner/",
    isOtherSite: true,
  },
  {
    name: "Biology",
    link: "https://matricula.co.in/category/blog/biology/",
    isOtherSite: true,
  },
  {
    name: "Chemistry",
    link: "https://matricula.co.in/category/blog/chemistry/",
    isOtherSite: true,
  },
  {
    name: "English",
    link: "https://matricula.co.in/category/blog/english/",
    isOtherSite: true,
  },
  {
    name: "Geography",
    link: "https://matricula.co.in/category/blog/geography/",
    isOtherSite: true,
  },
  {
    name: "History",
    link: "https://matricula.co.in/category/blog/history/",
    isOtherSite: true,
  },
  {
    name: "Maths",
    link: "https://matricula.co.in/category/blog/maths/",
    isOtherSite: true,
  },
  {
    name: "Physics",
    link: "https://matricula.co.in/category/blog/physics-blog/",
    isOtherSite: true,
  },
  {
    name: "NCERT",
    link: "https://matricula.co.in/category/blog/ncert/",
    isOtherSite: true,
  },
];
