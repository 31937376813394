import React from 'react'

function RefundPolicy() {
    return (
        <div id='refund' className='flex-col-center padding'>
            <h1>Refund Policy</h1>
            <p className='list'>A refund request will be deemed valid only if it is made through an email to hey@matricula.co.in or by calling us on +91 7439709486 within 3 days of placing the order. Refunds shall be made to bank account within 10 to 15 working days of receipt of cancellation request.</p>
        </div>
      )
}

export default RefundPolicy