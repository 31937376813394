import { useEffect } from "react";
import { BsDownload, BsYoutube, BsFillFileEarmarkFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateStudentActivityStatus } from "../../../data/axiosCalls/updateStudentActivityStatus";

import cheatSheet from "../../../file/NEET JEE CHEAT SHEET.pdf";
import samplequestions from "../../../file/SAMPLE-QUESTION-PAPER.pdf";
import { userDetails } from "../../../redux/actions/userAction";
import { downloadFile } from "../../../utils/downloadFile";

export const ResourceDataLogic = () => {
  // Can be a custom hook
  const { data: user } = useSelector((state) => state.userDetailsReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userDetails());
  }, [dispatch]);

  const navigate = useNavigate();

  useEffect(() => {
    updateStudentActivityStatus(user, { testimonialStatus: true });
    // eslint-disable-next-line
  }, []);

  const handleClickCheat = () => {
    downloadFile(cheatSheet);
  };

  const handleClickDownloadSampleQuestionPaper = () => {
    downloadFile(samplequestions);
  };
  const handleClickSolutions = async () => {
    await updateStudentActivityStatus(user, { videoSolutionStatus: true });
    navigate("/video-solutions");
  };

  const handleClickRedirect = (redirectLink) => {
    navigate(redirectLink);
  };

  const ResourceItems = [
    {
      cardTitle: "All About MANTRA - Videos and Solutions",
      CardIcon: <BsYoutube style={{ width: "4rem", height: "3rem" }} />,
      cardText: "Watch Videos",
      cardLink: handleClickSolutions,
    },
    {
      cardTitle: "MANTRA Sample Question Paper",
      CardIcon: <BsDownload style={{ width: "4rem", height: "3rem" }} />,
      cardText: "Download",
      cardLink: handleClickDownloadSampleQuestionPaper,
    },
    {
      cardTitle: "MANTRA Student Testimonials",
      CardIcon: <BsYoutube style={{ width: "4rem", height: "3rem" }} />,
      cardText: "Watch Videos",
      cardLink: () => handleClickRedirect("/testimonials"),
    },
    {
      cardTitle: "NEET and JEE Cheat Sheet",
      CardIcon: <BsDownload style={{ width: "4rem", height: "3rem" }} />,
      cardText: "Download",
      cardLink: handleClickCheat,
    },
    {
        cardTitle: "Terms and Conditions",
        CardIcon: <BsFillFileEarmarkFill style={{ width: "4rem", height: "3rem" }} />,
        cardText: "Read",
        cardLink: () => handleClickRedirect('/terms-and-conditions'),
    },
    {
        cardTitle: "Refund Policy",
        CardIcon: <BsFillFileEarmarkFill style={{ width: "4rem", height: "3rem" }} />,
        cardText: "Read",
        cardLink: () => handleClickRedirect('/refund-policy'),
    },
  ];
  return {
    ResourceItems,
    handleClickCheat,
    handleClickDownloadSampleQuestionPaper,
  };
};
