import React from 'react';
import { useStyles } from './Blog.style.js';
import AppBar from '@mui/material/AppBar';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {BlogLogic} from "./BlogLogic"
import blogBannerDesktop from '../../images/blogBannerDesktop.png';
import blogBannerMobile from '../../images/blogBannerMobile.png';
import BlogCard from '../../Components/BlogCard/BlogCard.js';

function Blog() {
  const classes = useStyles();

  const {
    blogs,
    redirectHandler,
    copyHandler
} = BlogLogic();
  return (
    <div className="Blog">
      <AppBar className={classes.appBar} position="static">
      <img alt="Mantra" className="downloads-web-banner"
                        src={blogBannerDesktop}
                        style={{ width: "100%",borderRadius: "0rem" }} />

                    <img alt="Mantra" className="downloads-mobile-banner"
                        src={blogBannerMobile}
                        style={{ width: "100%", margin: "0rem 0rem 0rem 0rem", height: "19rem", borderRadius: "0rem" }} />
      

      </AppBar>
      <Container maxWidth="lg" className={classes.blogsContainer}>
        <Typography variant="h5" className={classes.blogTitle}>
          Blogs
        </Typography>
        <Grid style={{width:"100%", height:"100%"}} >
          <Grid className={classes.parentGrid} item xs={12}>
            {blogs && blogs.body.map((currentElement, index)=>{
              return(
              <BlogCard currentElement={currentElement} index={index} classes={classes} redirectHandler={redirectHandler} copyHandler={copyHandler}/>
              )
            })}
          </Grid>
        </Grid>
        <Box my={4} className={classes.paginationContainer}>
        </Box>
      </Container>
    </div>
  );
}



export default Blog;