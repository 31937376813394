export const OTP_REQUEST = 'OTP_REQUEST'
export const OTP_SUCCESS = 'OTP_SUCCESS'
export const OTP_FAILED = 'OTP_FAILED'

export const OTP_VERIFY_REQUEST = 'OTP_VERIFY_REQUEST'
export const OTP_VERIFY_SUCCESS = 'OTP_VERIFY_SUCCESS'
export const OTP_VERIFY_FAILED = 'OTP_VERIFY_FAILED'

export const VERIFY_OTP_REQUEST = "VERIFY_OTP_REQUEST"
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS"
export const VERIFY_OTP_FAILED = "VERIFY_OTP_FAILED"


export const REGISTRATION_REQUEST = "REGISTRATION_REQUEST"
export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS"
export const REGISTRATION_FAILED = "REGISTRATION_FAILED"


export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST"
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS"
export const USER_DETAILS_FAILED  = "USER_DETAILS_FAILED"

export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILED = "LOGIN_FAILED"
export const USER_LOGOUT = "USER_LOGOUT"
