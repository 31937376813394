import React from 'react';
import { Link } from 'react-router-dom';

import {
    ForgotPasswordContainer,
    ForgotPasswordBox,
    Header,
    Heading,
    NumberInput,
    ResetPasswordButton,
    BackButton
} from "./ForgotPassword.element"
import {ForgotPasswordLogic} from "./ForgotPasswordLogic"

export default function ForgotPassword() {
    const {
        sendPasswordHandler,
        number,
        setNumber,
    } = ForgotPasswordLogic();
  return (
      <>
        <ForgotPasswordContainer>
            <ForgotPasswordBox >
                <Header>
                    <Heading >Forgot password?</Heading>
                </Header>
                <NumberInput
                onChange={(e) =>{setNumber(e.target.value)}}
                value={number}
                placeholder="Enter Your mobile number" type="number"/>
                <ResetPasswordButton
                onClick={sendPasswordHandler}
                >
                    Get password
                </ResetPasswordButton>
                <Link to="/login" style={{textDecoration:"none" }}>
                    <BackButton>Back</BackButton>
                </Link>
            </ForgotPasswordBox>
      </ForgotPasswordContainer>
      </>
  );
}
