import { Card, CardActions, CardContent, Typography } from "@mui/material";
import React from "react";
import '../Resourse.css';

function ResourceCard({cardTitle = '', CardIcon = <div></div>, cardText='', cardLink = () => {}}) {
  return (
    <Card className="ResourseCard" onClick={() => cardLink()}>
      <CardContent style={{ backgroundColor: "#b3ccfa" }}>
        <Typography
          variant="h5"
          style={{ margin: "2rem", fontWeight: "700", width: "16rem" }}
        >
          {cardTitle}
        </Typography>
      </CardContent>

      <CardActions>
        {CardIcon}
        <Typography
          variant="h6"
          style={{ marginLeft: "1rem", fontWeight: "700", width: "10rem" }}
        >
          {cardText}
        </Typography>
      </CardActions>
    </Card>

  );
}

export default ResourceCard;
