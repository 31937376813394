import InfoCard from './InfoCard'
import './Info.css'
import { InfoData } from './InfoData'

function Info() {
  return (
    <div className='info-wrapper padding-default'>
        <h2>Be among India's brightest minds</h2>
        <div className='info-list'>
          {InfoData.map(info => (<InfoCard {...info}/>))}
        </div>
    </div>
  )
}

export default Info