import React from "react";
import "./Highlights.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import RegImg from "../images/registration.png";
import Prev1 from "../../images/prev-year/1.jpg";
import Prev2 from "../../images/prev-year/2.jpg";
import Prev3 from "../../images/prev-year/3.jpg";
import Prev4 from "../../images/prev-year/4.jpg";
import Prev5 from "../../images/prev-year/5.jpg";

function Highlights() {
  const images = [Prev1, Prev2, Prev3, Prev4, Prev5]
  return (
    <>
      <div className="highlight-wrapper padding-default">
        <div className="swiper-div">
          <Swiper
            slidesPerView={1}
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            loop={true}
            pagination={{ el: "#swiper-pagination", clickable: true }}
          >
            {images.map((img, index) => (
              <SwiperSlide className="swiper-slide">
                <img
                  src={img}
                />
              </SwiperSlide>
            ))}
            <div id="swiper-pagination"></div>
          </Swiper>
        </div>
        <div className="highlight-text">
          <h2>Highlights</h2>
          <p>From Previous Year</p>
        </div>
      </div>
      <div className="registration-count padding-default">
        <div>
          <h2>More than 600K+ students have already registered in Mantra 2023!</h2>
          <p>What are you waiting for? </p>
          <a href="#register">Register Now</a>
        </div>
        <div className="reg-img">
          <img src={RegImg} />
        </div>
      </div>
    </>
  );
}

export default Highlights;
