import physics from '../../images/physics-nuclear-svgrepo-com.svg';
import chemistry from '../../images/chemistry-svgrepo-com.svg'
import biology from "../../images/cells-cell-svgrepo-com.svg"
import mathematics from '../../images/maths-add-svgrepo-com.svg';
import mentalAbility from '../../images/brain-svgrepo-com.svg'
import zoology from '../../images/petri-dish-biology-svgrepo-com.svg'

const examPattern = [
    {
        id: 1,
        title: 'EXAMINATION PATTERN FOR CLASS VI , VII, VIII & IX STUDYING STUDENTS',
        pattern: [
            {
                id: 1,
                title: 'Total no. of MCQs',
                value: '30 MCQ'
            },
            {
                id: 2,
                title: 'Duration',
                value: '1 Hr',
            },
            {
                id: 3,
                title: 'Total Marks',
                value: '90 (No Negative Marking)',
            },
            {
                id: 4,
                title: 'Mental Ability',
                value: '6'
            },
            {
                id: 5,
                title: 'Mathematics (6)',
                value: '6'
            },
            {
                id: 6,
                title: 'Science (18)',
                value: 'Physics (6), Chemistry (6), Biology (6)'
            }
        ]

    },
    {
        id: 2,
        title: 'EXAMINATION PATTERN FOR CLASS X STUDYING STUDENTS (MEDICAL ASPIRANTS)',
        pattern: [
            {
                id: 1,
                title: 'Total no. of MCQs',
                value: '30 MCQ'
            },
            {
                id: 2,
                title: 'Duration',
                value: '1 Hr',
            },
            {
                id: 3,
                title: 'Total Marks',
                value: '90 (No Negative Marking)',
            },
            {
                id: 4,
                title: 'Mental Ability',
                value: '4'
            },
            {
                id: 5,
                title: 'Biology',
                value: '10'
            },
            {
                id: 6,
                title: 'Science (16)',
                value: 'Physics (8), Chemistry (8)'
            }
        ]
    },
    {
        id: 3,
        title: 'EXAMINATION PATTERN FOR CLASS X STUDYING STUDENTS (ENGINEERING ASPIRANTS)',
        pattern: [
            {
                id: 1,
                title: 'Total no. of MCQs',
                value: '30 MCQ'
            },
            {
                id: 2,
                title: 'Duration',
                value: '1 Hr',
            },
            {
                id: 3,
                title: 'Total Marks',
                value: '90 (No Negative Marking)',
            },
            {
                id: 4,
                title: 'Mental Ability',
                value: '4'
            },
            {
                id: 5,
                title: 'Mathematics',
                value: '10'
            },
            {
                id: 6,
                title: 'Science (16)',
                value: 'Physics (8), Chemistry (8)'
            }
        ]
    },
    {
        id: 4,
        title: 'EXAMINATION PATTERN FOR CLASS XI & XII STUDYING & PASSED STUDENTS (ENGINEERING ASPIRANTS)',
        pattern: [
            {
                id: 1,
                title: 'Total no. of MCQs',
                value: '30 MCQ'
            },
            {
                id: 2,
                title: 'Duration',
                value: '1 Hr',
            },
            {
                id: 3,
                title: 'Total Marks',
                value: '90 (No Negative Marking)',
            },
            {
                id: 4,
                title: 'Chemistry',
                value: '10'
            },
            {
                id: 5,
                title: 'Mathematics',
                value: '10'
            },
            {
                id: 6,
                title: 'Physics',
                value: '10'
            }
        ]
    },
    {
        id: 5,
        title: 'EXAMINATION PATTERN FOR CLASS XI & XII STUDYING & PASSED STUDENTS (MEDICAL ASPIRANTS)',
        pattern: [
            {
                id: 1,
                title: 'Total no. of MCQs',
                value: '30 MCQ'
            },
            {
                id: 2,
                title: 'Duration',
                value: '1 Hr',
            },
            {
                id: 3,
                title: 'Total Marks',
                value: '90 (No Negative Marking)',
            },
            {
                id: 4,
                title: 'Chemistry',
                value: '10'
            },
            {
                id: 5,
                title: 'Biology',
                value: '10'
            },
            {
                id: 6,
                title: 'Physics',
                value: '10'
            }
        ]
    }
]


const syllabus = [
    {
        id: 1,
        class: 'VI',
        subjects: [
            {
                id: 1,
                subject: 'Physics',
                chapters: ["Motion and Measurement of Distances","Light Shadow and Reflection","Electricity and Circuits","Fun with Magnets"]
            },
                
            {
                id: 2,
                subject: 'Chemistry',
                chapters: ["Sorting Material into Group","Separation of Substances","Changes around us"]
            },
            {
                id: 3,
                subject: 'Biology',
                chapters: ["Components of Food","Getting to Know Plants","Body Movements","Living Organism and its Surroundings"]
            },
            {
                id: 4,
                subject: 'Mathematics',
                chapters: ["Number System","Algebra","Ratio and Proportion","Geometry"]
            },
            {
                id: 5,
                subject: 'Mental Ability',
                chapters: ["Jump paragraphs","Assertion and Reason","Riddles and Puzzles"]
            }
        ]
    },
    {
        id: 1,
        class: 'VII',
        subjects: [
            {
                id: 1,
                subject: 'Physics',
                chapters: [
                    'Heat',
                    'Winds, Storms and Cyclones',
                    'Motion and Time',
                    'Electric Currents and Its Effect',
                    'Light'
                  ]
            },
                
            {
                id: 2,
                subject: 'Chemistry',
                chapters: [
                    'Fibre to Fabric',
                    'Acids, Bases and Salts',
                    'Physical and Chemical Changes',
                    'Water - A precious resource'
                  ]
                  
            },
            {
                id: 3,
                subject: 'Biology',
                chapters: [
                    'Nutrition in Plants',
                    'Nutrition in Animals',
                    'Weather, Climate and Adaptation of Animals to Climate',
                    'Respiration in Organisms',
                    'Forests'
                  ]
            },
            {
                id: 4,
                subject: 'Mathematics',
                chapters: [
                    'Integers',
                    'Fractions and Decimals',
                    'Data Handling',
                    'Simple Equations',
                    'Line and Angles',
                    'Triangle and its Properties',
                    'Congruence of Triangles',
                    'Comparing Quantities',
                    'Rational Number',
                    'Symmetry',
                    'Geometry',
                    'Perimeter and Area'
                  ]
            },
            {
                id: 5,
                subject: 'Mental Ability',
                chapters: [
                    'Series, Direction Test',
                    'Mathematical Operations',
                    'Missing Character',
                    'Puzzles',
                    'Mirror & Water Image'
                  ]
            }
        ]
    },
    {
        id: 3,
        class: 'VIII',
        subjects: [
            {
                id: 1,
                subject: 'Physics',
                chapters: [
                    'Force and Pressure',
                    'Friction',
                    'Sound',
                    'Chemical Effects of Electric Current Chemistry: Synthetic Fibres and Plastics',
                    'Materials: Metals and Non-Metals'
                  ]
            },
                
            {
                id: 2,
                subject: 'Chemistry',
                chapters: [
                    'Fibre to Fabric',
                    'Acids, Bases and Salts',
                    'Physical and Chemical Changes'
                  ]
                  
            },
            {
                id: 3,
                subject: 'Biology',
                chapters: [
                    'Crop Production and Management',
                    'Microorganisms - Friend and Foe',
                    'Conservation of Plants and Animals',
                    'Cell- Structure and Functions'
                  ]
            },
            {
                id: 4,
                subject: 'Mathematics',
                chapters:[
                    'Rational Numbers',
                    'Linear Equations in One Variable',
                    'Understanding Quadrilaterals',
                    'Data Handling',
                    'Squares and Square Roots',
                    'Cubes and Cube Roots',
                    'Comparing Quantities'
                  ]
                  
            },
            {
                id: 5,
                subject: 'Mental Ability',
                chapters: [
                    'Verbal & Non-Verbal Reasoning: Number and Alphabet Test',
                    'Series',
                    'Classification, Analogy',
                    'Direction Test',
                    'Coding and Decoding, Problems Based on Figures'
                  ]
            }
        ]
    },
    {
        id: 4,
        class: 'IX',
        subjects: [
            {
                id: 1,
                subject: 'Physics',
                chapters: [ 'Motion', 'Force and Laws of Motion', 'Gravitation' ]
            },
                
            {
                id: 2,
                subject: 'Chemistry',
                chapters: [
                    'Matter in Our Surroundings',
                    'Is Matter Around Us Pure? (excluding separation techniques)',
                    'Atoms and Molecules (excluding Mole Concept)'
                  ]
                  
                  
            },
            {
                id: 3,
                subject: 'Biology',
                chapters:[ 'The Fundamental Unit of Life', 'Tissues' ]
            },
            {
                id: 4,
                subject: 'Mathematics',
                chapters:[
                    'Number Systems',
                    'Polynomials',
                    'Coordinate Geometry',
                    'Lines and Angles',
                    'Triangles',
                    'Linear Equations in Two Variables'
                  ]
                  
                  
            },
            {
                id: 5,
                subject: 'Mental Ability',
                chapters:[
                    'General Logical Reasoning',
                    'Analytical Reasoning',
                    'Figure Matrix',
                    'Problems Based on Figures'
                  ]
            }
        ]
    },
    {
        id: 5,
        class: 'X',
        aspirants: 'Medical',
        subjects: [
            {
                id: 1,
                subject: 'Physics',
                chapters: [
                    'Reflection and Refraction',
                    'Human Eye and Colourful World (excluding Colour of the sun at sunrise and sunset)',
                    'Electricity'
                  ]
            },
                
            {
                id: 2,
                subject: 'Chemistry',
                chapters: [
                    'Chemical Reactions and Equations',
                    'Acids, Bases and Salts',
                    'Metals and Non-Metals'
                  ]
                  
                  
            },
            {
                id: 3,
                subject: 'Biology',
                chapters:[
                    'Life Processes',
                    'Control and Coordination',
                    'How do Organisms Reproduce?'
                  ]
            },
            {
                id: 4,
                subject: 'Mental Ability',
                chapters:[
                    'Verbal & Non-Verbal Reasoning',
                    'Series',
                    'Analogy Classification',
                    'Coding and Decoding',
                    'General Logical and Analytical Reasoning',
                    'Problems Based on Figures',
                    'Data Interpretation'
                  ]
            }
        ]
    },
    {
        id: 6,
        class: 'X',
        aspirants: 'Engineering',
        subjects: [
            {
                id: 1,
                subject: 'Physics',
                chapters: [
                    'Reflection and Refraction',
                    'Human Eye and Colourful World (excluding Colour of the sun at sunrise and sunset)',
                    'Electricity'
                  ]
            },
                
            {
                id: 2,
                subject: 'Chemistry',
                chapters: [
                    'Chemical Reactions and Equations',
                    'Acids, Bases and Salts',
                    'Metals and Non-Metals'
                  ]
                  
                  
            },
            {
                id: 3,
                subject: 'Mathematics',
                chapters:[
                    'Real Numbers',
                    'Polynomials',
                    'Pair of Linear Equations in Two Variables',
                    'Quadratic Equations',
                    'Arithmetic Progressions',
                    'Coordinate Geometry',
                    'Triangles',
                    'Introduction to Trigonometry',
                    'Some Applications of Trigonometry'
                  ]
                  
            },
            {
                id: 4,
                subject: 'Mental Ability',
                chapters:[
                    'Verbal & Non-Verbal Reasoning',
                    'Series',
                    'Analogy Classification',
                    'Coding and Decoding',
                    'General Logical and Analytical Reasoning',
                    'Problems Based on Figures',
                    'Data Interpretation'
                  ]
            }
        ]
    },
    {
        id: 7,
        class: 'XI',
        aspirants: 'Medical',
        subjects: [
            {
                id: 1,
                subject: 'Physics',
                chapters: [
                    'Units and Measurements,',
                    'Motion in a Straight Line',
                    'Motion in a Plane',
                    'Laws of Motion',
                    'Work, Energy and Power',
                    'System of Particles and Rotational Motion'
                  ]
            },
                
            {
                id: 2,
                subject: 'Chemistry',
                chapters: [
                    'Some Basic Concepts of Chemistry',
                    'Structure of Atom',
                    'Classification of Elements and Periodicity in Properties',
                    'Chemical Bonding and Molecular Structure',
                    'States of Matter',
                    'Thermodynamics'
                  ]
                  
                  
            },
            {
                id: 3,
                subject: 'Biology',
                chapters:[
                    'The Unit of Life',
                    'Cell Cycle and Cell Division',
                    'The Living World',
                    'Biological Classification',
                    'Morphology of Flowering Plants'
                  ]
                  
            },
            {
                id: 4,
                subject: 'Zoology',
                chapters:[
                    'Structural Organisation in Animals - Animal Tissues',
                    'Biomolecules',
                    'Breathing and Exchange of Gases',
                    'Body Fluids and Circulation',
                    'Excretory Products and Their Elimination',
                    'Locomotion and Movement'
                  ]                  
            }
        ]
    },
    {
        id: 8,
        class: 'XI',
        aspirants: 'Engineering',
        subjects: [
            {
                id: 1,
                subject: 'Physics',
                chapters: [
                    'Physical World',
                    'Units and Measurements,',
                    'Motion in a Straight Line',
                    'Motion in a Plane',
                    'Laws of Motion',
                    'Work, Energy and Power',
                    'System of Particles and Rotational Motion'
                  ]
                  
            },
                
            {
                id: 2,
                subject: 'Chemistry',
                chapters: [
                    'Some Basic Concepts of Chemistry',
                    'Structure of Atom',
                    'Classification of Elements and Periodicity in Properties',
                    'Chemical Bonding and Molecular Structure',
                    'States of Matter',
                    'Thermodynamics'
                  ]
            },
            {
                id: 3,
                subject: 'Mathematics',
                chapters:[
                    'Sets',
                    'Relations and Functions',
                    'Trigonometric Functions',
                    'Complex Numbers and Quadratic Equations',
                    'Linear Inequalities',
                    'Permutations and Combinations',
                    'Sequences and Series'
                  ]                  
            }
        ]
    },
    {
        id: 9,
        class: 'XII',
        aspirants: 'Medical',
        passed: true,
        subjects: [
            {
                id: 1,
                subject: 'Physics',
                chapters:[
                    'Electric Charges and Field',
                    'Electrostatics Potential and Capacitance',
                    'Current Electricity',
                    'Moving Charges and Magnetism',
                    'Magnetism and Matter',
                    'Electromagnetic Induction',
                    'Alternating Current'
                  ]
                  
                  
            },
                
            {
                id: 2,
                subject: 'Chemistry',
                chapters: [
                    'Solutions',
                    'Electrochemistry',
                    'Chemical Kinetics',
                    'The d- and f-Block Elements',
                    'Coordination Compounds'
                  ]
                  
            },
            {
                id: 3,
                subject: 'Biology',
                chapters:[
                    'Sexual Reproduction in Flowering Plants',
                    'Principles of Inheritance and Variation',
                    'Molecular Basis of Inheritance',
                    'Microbes in Human Welfare'
                  ]      
            },
            {
                id: 4,
                subject: 'Zoology',
                chapters:[
                    'Human Reproduction',
                    'Reproductive Health',
                    'Human Health and Disease',
                    'Biotechnology: Principles and Processes',
                    'Biotechnology and Its Applications'
                  ]
            }
        ]
    },
    {
        id: 9,
        class: 'XII',
        aspirants: 'Engineering',
        subjects: [
            {
                id: 1,
                subject: 'Physics',
                chapters: [
                    'Electric Charges and Field',
                    'Electrostatics Potential and Capacitance',
                    'Current Electricity',
                    'Moving Charges and Magnetism',
                    'Magnetism and Matter',
                    'Electromagnetic Induction',
                    'Alternating Current'
                  ]
                  
            },
                
            {
                id: 2,
                subject: 'Chemistry',
                chapters: [
                    'Solutions',
                    'Electrochemistry',
                    'Chemical Kinetics',
                    'The d- and f-Block Elements',
                    'Coordination Compounds'
                  ]
            },
            {
                id: 3,
                subject: 'Mathematics',
                chapters:[
                    'Relations and Functions',
                    'Inverse Trigonometric Functions',
                    'Matrices',
                    'Determinants',
                    'Continuity and Differentiability',
                    'Application of Derivatives',
                    'Integrals',
                    'Applications of Integrals'
                  ]        
            }
        ]
    },
]

const images = {
    "Physics": physics,
    "Chemistry": chemistry,
    "Biology": biology,
    "Mathematics": mathematics,
    "Zoology": zoology,
    "Mental Ability": mentalAbility,
}



export {examPattern, syllabus,images}