export const   EMAIL_VERIFY_REQUEST = 'EMAIL_VERIFY_REQUEST'
export const   EMAIL_VERIFY_SUCCESS = 'EMAIL_VERIFY_SUCCESS'
export const   EMAIL_VERIFY_FAILED = 'EMAIL_VERIFY_FAILED'


export const OTP_VERIFY_REQUEST = 'OTP_VERIFY_REQUEST'
export const OTP_VERIFY_SUCCESS = 'OTP_VERIFY_SUCCESS'
export const OTP_VERIFY_FAILED = 'OTP_VERIFY_FAILED'

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_FAILED = 'UPDATE_PASSWORD_FAILED'