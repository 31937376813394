import Info1 from '../../../../images/info1.png'
import Info2 from '../../../../images/info2.png'
import Info3 from '../../../../images/info3.png'
import Info4 from '../../../../images/info4.png'
import Info5 from '../../../../images/info5.png'
import Info6 from '../../../../images/info6.png'

export const InfoData = [
    {
        image: Info1,
        title: 'Online Exam Date',
        description: [
            'November 11th - 13th, 2022 (All Slots Booked)','December 3rd - 31st, 2022','January 1st, 2023 - April 31st, 2023'
        ]
    },
    {
        image: Info2,
        title: 'Get up to 100% Scholarship',
        conditions: true,
        description: ['Win up to 100% Scholarship on our Classroom courses']
    },
    {
        image: Info3,
        title: 'Win A Trip to ISRO',
        conditions: true,
        description: ['10 lucky winners win a trip to ISRO']
    },
    {
        image: Info4,
        title: 'Cash Awards',
        description: ['Top Performers* will get up to 10 Lakhs scholarship for higher studies']
    },
    {
        image: Info5,
        title: 'Eligibility',
        description: ['Class VI, VII, VIII, IX, X, XI& XII Studying/ Passed Students']
    },
    {
        image: Info6,
        title: 'Exam Timing',
        description: ['Online Timing: 10 AM to 09 PM']
    }
]