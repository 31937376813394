import {loadScript} from "../../utils/loadScript"
import Swal from 'sweetalert2'
import {useSelector,useDispatch} from "react-redux"
import { makePaymentRazorpayHelper, successPaymentRazorpayHelper } from "./helper"
import { userDetails } from "../../redux/actions/userAction"
export const PaymentCardLogic = () =>{
    const dispatch = useDispatch();

    const {userInfo} = useSelector(state => state.userSignin)
    const {userInfo:registerUserInfo} = useSelector(state => state.userSignup)

    const {REACT_APP_RAZORPAY_ID} = process.env

    // Display Razorpay and make Payment
  async function displayRazorpay() {
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?')
      return
    }
    // create order of Razorpay from backend and get orderId and amount
    const data = await makePaymentRazorpayHelper((userInfo || registerUserInfo) && (userInfo ? userInfo.data._id : registerUserInfo.data._id))
    const options = {
      key: REACT_APP_RAZORPAY_ID,
      currency: data.currency,
      amount: data.amount,
      order_id: data.orderId,
      name: 'Matricula',
      description: 'Pay your registration Fee',
      handler: async function () {
        // Payment Success Helper
        const res = await successPaymentRazorpayHelper(
          data.orderId,
          (userInfo || registerUserInfo) && (userInfo ? userInfo.data._id : registerUserInfo.data._id),
        )
        // console.log('====================================');
        // console.log(res);
        // console.log('====================================');
        if (res) {
            Swal.fire({
                icon: 'success',
                title: `Transaction completed by ${(userInfo || registerUserInfo) && (userInfo ? userInfo.data.stundetName : registerUserInfo.data.stundetName)}  to MANTRA`,
            })
            dispatch(userDetails())
        }
      },
      prefill: {
        email: (userInfo || registerUserInfo) && (userInfo ? userInfo.data.studentEmail : registerUserInfo.data.studentEmail),
        phone_number: (userInfo || registerUserInfo) && (userInfo ? userInfo.data.mobileNumber : registerUserInfo.data.mobileNumber)
      }
    }
    const paymentObject = new window.Razorpay(options)
    paymentObject.open()
  }

    return {
        displayRazorpay
    }
}