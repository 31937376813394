import { combineReducers } from 'redux';
import { SmsVerificationForForgotPasswordReducer } from './forgotPasswordReducer';
import {otpReducer , verifyOtpReducer , registerReducer , userDetailsReducer ,loginReducer}  from './userReducers';
import { getAllBlogsReducer, getSingleBlogReducer } from './blogReducer'
const rootReducer = combineReducers({
    otpReducer,
    verifyOtpReducer,
    userSignup:registerReducer,
    userDetailsReducer,
    userSignin:loginReducer,
    getAllBlogs: getAllBlogsReducer,
    getSingleBlog: getSingleBlogReducer,
    SmsVerificationForForgotPassword:SmsVerificationForForgotPasswordReducer
});

export default rootReducer;