import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import { PaymentCardLogic } from "./PaymentCard.logic"

export default function PaymentCard() {
    const { displayRazorpay } = PaymentCardLogic()
    return (
        <>
            <div style={{ width: '100%', height: "100vh", position: 'absolute', top: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Card sx={{ maxWidth: 345 }}>
                    <CardActionArea>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Payment Pending
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                You need to pay the fees to see more details, please pay 149 INR -/
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        <Button
                            onClick={displayRazorpay}
                            size="small" color="primary">
                            Pay Now
                        </Button>
                    </CardActions>
                </Card>
            </div>
        </>
    );
}
