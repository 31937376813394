import {
    EMAIL_VERIFY_REQUEST,
  EMAIL_VERIFY_SUCCESS,
  EMAIL_VERIFY_FAILED,
  OTP_VERIFY_REQUEST,
  OTP_VERIFY_SUCCESS,
  OTP_VERIFY_FAILED,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
   UPDATE_PASSWORD_FAILED,
  } from '../constants/forgotPasswordConstants'




/// EMAIL VERIFY FOR FORGOT PASSWORD ///

  
    const initialStateOtpVerify = {
        loading: false,
        error: null,
        isAuthenticated:false
        }
    export const SmsVerificationForForgotPasswordReducer = (
        state = { initialStateOtpVerify},
        action
      ) => {
        switch (action.type) {
          case EMAIL_VERIFY_REQUEST:
            return { 
              ...state, loading: true,
              isAuthenticated:false,
              error: null,
              data:null,
            }
          case EMAIL_VERIFY_SUCCESS:
            return {
              loading: false,
              isAuthenticated:true,
              error: null,
              data: action.payload
            }
          case EMAIL_VERIFY_FAILED:
            return {
              loading: false,
              error: action.payload,
              isAuthenticated:false,
              data: null
            }
          default:
            return state
        }
      }



          ///    OTP VERIFY  REDUCER FOR FORGOT PASSWORD   ///
    export const otpVerificationForForgotPasswordReducer = (
        state = { initialStateOtpVerify},
        action
      ) => {
        switch (action.type) {
          case OTP_VERIFY_REQUEST:
            return { ...state, loading: true}
          case OTP_VERIFY_SUCCESS:
            return {
              loading: false,
              isAuthenticated:true,
              error: null
    
            }
          case OTP_VERIFY_FAILED:
            return {
              loading: false,
              error: action.payload,
              isAuthenticated:false
            }
          default:
            return state
        }
      }
      
      
      
      
                ///    UPDATE  PASSWORD  ///
          export const updatePasswordReducer = (
              state = { initialStateOtpVerify},
              action
            ) => {
              switch (action.type) {
                case UPDATE_PASSWORD_REQUEST:
                  return { ...state, loading: true}
                case UPDATE_PASSWORD_SUCCESS:
                  if (localStorage.getItem('forgotPasswordDetails')) {
                      localStorage.removeItem('forgotPasswordDetails')
                    }
                  return {
                    loading: false,
                    isAuthenticated:true,
                    error: null,
                    data: action.payload
                  }
                case UPDATE_PASSWORD_FAILED:
                  return {
                    loading: false,
                    error: action.payload,
                    isAuthenticated:false
                  }
                default:
                  return state
              }
            }

