import React, { useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import "./Profile.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { userDetails } from "../../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux/es/exports";
import PaymentCard from "../PaymentCard";

function Profile() {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.userDetailsReducer);

  useEffect(() => {
    dispatch(userDetails());
  }, [dispatch]);

  return (
    <div className="flex">
      <SideBar />
      {data && data.data.isPaid ? (
        <>
          <div className="container profile">
            <div className="main-body" style={{ width: "100%" }}>
              <div className="gutters-sm move" style={{ width: "100%" }}>
                <div className="col-md-4 mb-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex flex-column align-items-center text-center">
                        <img
                          src="https://bootdey.com/img/Content/avatar/avatar7.png"
                          alt="Admin"
                          className="rounded-circle"
                          width="150"
                        />
                        <div className="mt-3">
                          <h4>{data && data.data.stundetName}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8" style={{ width: "100%" }}>
                  <div className="card mb-3">
                    <div className="card-body">
                      <h3 className="d-flex align-items-center mb-3">
                        Personal Details
                      </h3>

                      <div className="row">
                        <div className="col-sm-3">
                          <h6 className="mb-0">Full Name</h6>
                        </div>
                        <div className="col-sm-9 text-secondary">
                          {data && data.data.stundetName}
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-sm-3">
                          <h6 className="mb-0">Guardian Name</h6>
                        </div>
                        <div className="col-sm-9 text-secondary">
                          {data && data.data.GuardianName}
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-sm-3">
                          <h6 className="mb-0">Date of Birth</h6>
                        </div>
                        <div className="col-sm-9 text-secondary">
                          {data && data.data.dateOfBirth}
                        </div>
                      </div>
                      <hr />

                      <div className="row">
                        <div className="col-sm-3">
                          <h6 className="mb-0">Payment Status</h6>
                        </div>
                        <div className="col-sm-9 text-secondary">
                          {data && data.data.isPaid ? "Paid" : "Not Paid"}
                        </div>
                      </div>
                      <hr />

                      <div className="row">
                        <div className="col-sm-3">
                          <h6 className="mb-0">HomeTown</h6>
                        </div>
                        <div className="col-sm-9 text-secondary">
                          {data && data.data.homeTown}
                        </div>
                      </div>
                      <hr />

                      <div className="row">
                        <div className="col-sm-3">
                          <h6 className="mb-0">State</h6>
                        </div>
                        <div className="col-sm-9 text-secondary">
                          {data && data.data.state}
                        </div>
                      </div>

                      {/* <div className="row">
                                        <div className="col-sm-12">
                                            <a className="btn btn-info " target="__blank" href="https://www.bootdey.com/snippets/view/profile-edit-data-and-skills">Edit</a>
                                        </div>
                                    </div> */}
                    </div>
                  </div>

                  <div className="row gutters-sm">
                    <div className="col-sm-6 mb-3">
                      <div className="card h-100">
                        <div className="card-body">
                          <h3 className="d-flex align-items-center mb-3">
                            Contact Details
                          </h3>
                          <div className="row">
                            <div className="col-sm-3">
                              <h6 className="mb-0">Mobile Number</h6>
                            </div>
                            <div className="col-sm-9 text-secondary">
                              {data && data.data.mobileNumber}
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-sm-3">
                              <h6 className="mb-0">Email</h6>
                            </div>
                            <div className="col-sm-9 text-secondary">
                              {data && data.data.studentEmail}
                            </div>
                          </div>

                          <hr />
                          {/* <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">District</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                          {data && data.data.District}
                                        </div>
                                    </div> */}
                          {/* <hr /> */}
                          <div className="row">
                            <div className="col-sm-3">
                              <h6 className="mb-0">Roll Number</h6>
                            </div>
                            <div className="col-sm-9 text-secondary">
                              {data && data.data.rollNo}
                            </div>
                          </div>
                          <hr />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 mb-3">
                      <div className="card h-100">
                        <div className="card-body">
                          <h3 className="d-flex align-items-center mb-3">
                            Academic Details
                          </h3>
                          <div className="row">
                            <div className="col-sm-3">
                              <h6 className="mb-0">Class and Stream</h6>
                            </div>
                            <div className="col-sm-9 text-secondary">
                              {data && data.data.classAndStream}
                            </div>
                          </div>
                          <hr />
                          {/* <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">District</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                          {data && data.data.District}
                                        </div>
                                    </div> */}
                          {/* <hr /> */}
                          <div className="row">
                            <div className="col-sm-3">
                              <h6 className="mb-0">Exam Date</h6>
                            </div>
                            <div className="col-sm-9 text-secondary">
                              {data && data.data.ExamDate}
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-sm-3">
                              <h6 className="mb-0">Exam mode and Time</h6>
                            </div>
                            <div className="col-sm-9 text-secondary">
                              {data && data.data.examModeAndTime}
                            </div>
                          </div>
                          <hr />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <PaymentCard />
      )}
    </div>
  );
}

export default Profile;
