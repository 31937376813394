import React, { useEffect, useState } from 'react'

function Video({embedId}) {
    const [mute,setMute] =  useState(1)
    useEffect(() => {
        setMute(prev => 0)
    },[])
    return (
    <div className='padding-default'>
        <iframe className='yt-video' src={`https://www.youtube.com/embed/${embedId}?mute=${mute}&autoplay=1`} title="YouTube video player" frameborder="0" allow="autoplay" allowfullscreen></iframe>
    </div>
  )
}

export default Video