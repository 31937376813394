import React from "react";
import "./Register.css";
import useRegistrationLogic from "./RegistrationLogic";
import { Link } from "react-router-dom";

function Register() {
  const {
    handleChange,
    handleSubmit,
    handleChange1,
    handleSubmit1,
    handleChange2,
    handleSubmit2,
    verified,
    show,
    user,
    data,
    verifyData,
    loggedInData,
    daysOfYear,
  } = useRegistrationLogic();

  return (
    <div
      id="register"
      className="info-wrapper padding-default register-wrapper"
    >
      <h2>Register Now!</h2>
      {(verified && !loggedInData) ? (
        <section>
          <form onSubmit={handleSubmit2} accept-charset="UTF-8">
            <input
              className="form-control"
              id="name"
              type="text"
              name="name"
              value={user.name}
              size="60"
              placeholder="Student Name"
              onChange={handleChange2}
            />

            <input
              id="guardian-name"
              className="form-control"
              type="text"
              name="guardian"
              value={user.guardian}
              size="60"
              placeholder="Guardian Name"
              onChange={handleChange2}
            />

            <input
              id="homeTown"
              className="form-control"
              type="text"
              name="homeTown"
              value={user.homeTown}
              size="60"
              placeholder="Home Town/City"
              onChange={handleChange2}
            />

            {/* <label>Exam Mode & Time:</label> */}
            {/* <br />
                  <p style={{ fontWeight: "600" }}>10 AM, Online</p> */}
            <select
              name="state"
              className="form-control"
              onChange={handleChange2}
            >
              <option value="">Select State</option>
              <option value="Andaman and Nicobar Islands">
                Andaman and Nicobar Islands{" "}
              </option>
              <option value="Andhra Pradesh">Andhra Pradesh </option>
              <option value="Arunachal Pradesh">Arunachal Pradesh </option>
              <option value="Assam">Assam </option>
              <option value="Bihar">Bihar </option>
              <option value="Chandigarh">Chandigarh</option>
              <option value="Chhattisgarh">Chhattisgarh </option>
              <option value="Dadra & Nagar Haveli and Daman & Diu">
                Dadra & Nagar Haveli and Daman & Diu{" "}
              </option>
              <option value="Delhi">Delhi </option>
              <option value="Goa">Goa </option>
              <option value="Gujarat">Gujarat </option>
              <option value="Haryana">Haryana </option>
              <option value="Himachal Pradesh">Himachal Pradesh </option>
              <option value="Jammu and Kashmir">Jammu and Kashmir </option>
              <option value="Jharkhand">Jharkhand </option>
              <option value="Karnataka">Karnataka </option>
              <option value="Kerala">Kerala </option>
              <option value="Ladakh">Ladakh</option>
              <option value="Lakshadweep">Lakshadweep</option>
              <option value="Madhya Pradesh">Madhya Pradesh </option>
              <option value="Maharashtra">Maharashtra </option>
              <option value="Manipur">Manipur </option>
              <option value="Meghalaya">Meghalaya </option>
              <option value="Mizoram">Mizoram </option>
              <option value="Nagaland">Nagaland </option>
              <option value="Odisha">Odisha </option>
              <option value="Puducherry">Puducherry</option>
              <option value="Punjab">Punjab </option>
              <option value="Rajasthan">Rajasthan </option>
              <option value="Sikkim">Sikkim </option>
              <option value="Tamil Nadu">Tamil Nadu </option>
              <option value="Telangana">Telangana </option>
              <option value="Tripura">Tripura </option>
              <option value="Uttar Pradesh">Uttar Pradesh </option>
              <option value="Uttarakhand">Uttarakhand </option>
              <option value="West Bengal">West Bengal </option>
            </select>

            <input
              id="email"
              type="email"
              name="email"
              className="form-control"
              value={user.email}
              size="60"
              placeholder="Email"
              onChange={handleChange2}
            />
            <input
              className="form-control"
              id="date"
              type="text"
              name="date"
              value={user.date}
              size="60"
              onChange={handleChange2}
              placeholder="Date of Birth"
              onFocus={(e) => {
                e.target.type = "date";
              }}
            />
            <select
              name="class"
              onChange={handleChange2}
              className="form-control"
            >
              <option value="">Class & Stream </option>
              <option value="Class : 6"> Class : 6</option>
              <option value="Class : 7"> Class : 7</option>
              <option value="Class : 8"> Class : 8</option>
              <option value="Class : 9  Foundation">
                {" "}
                Class : 9 Foundation
              </option>
              <option value="Class : 10 Foundation">
                {" "}
                Class : 10 Foundation
              </option>
              <option value="Class : 11 Engineering/IIT JEE">
                {" "}
                Class : 11 Engineering/IIT JEE
              </option>
              <option value="Class : 11 Medical/NEET UG">
                {" "}
                Class : 11 Medical/NEET UG
              </option>
              <option value="Class : 12 Engineering/IIT JEE">
                {" "}
                Class : 12 Engineering/IIT JEE
              </option>
              <option value="Class : 12 Medical/NEET UG">
                {" "}
                Class : 12 Medical/NEET UG
              </option>
              <option value="NEET UG DROPPER"> NEET UG DROPPER</option>
              <option value="IIT JEE UG DROPPER"> IIT JEE UG DROPPER</option>
            </select>
            {/* <label>Exam Mode & Time:</label> */}
            {/* <br />
                  <p style={{ fontWeight: "600" }}>10 AM, Online</p> */}
            <select
              name="mode"
              className="form-control"
              onChange={handleChange2}
            >
              <option value="">Exam Mode & Time</option>
              <option value="10 AM, Online">10 AM, Online</option>
              <option value="11 AM, Online">11 AM, Online</option>
              <option value="12 PM, Online">12 PM, Online</option>
              <option value="01 PM, Online">01 PM, Online</option>
              <option value="02 PM, Online">02 PM, Online</option>
              <option value="03 PM, Online">03 PM, Online</option>
              <option value="04 PM, Online">04 PM, Online</option>
              <option value="05 PM, Online">05 PM, Online</option>
              <option value="06 PM, Online">06 PM, Online</option>
              <option value="07 PM, Online">07 PM, Online</option>
              <option value="08 PM, Online">08 PM, Online</option>
              <option value="09 PM, Online">09 PM, Online</option>
            </select>
            <select
              name="exam"
              onChange={handleChange2}
              className="form-control"
            >
              <option value="">Exam Date</option>
              {daysOfYear.map((day) => (
                <option key={day} value={day}>
                  {day}
                </option>
              ))}
            </select>
            <input
              id="referral"
              type="text"
              name="referral"
              className="form-control"
              value={user.referral}
              size="60"
              placeholder="Referred By (Optional)"
              onChange={handleChange2}
            />
            <button
              type="submit"
              id="edit-next"
              name="submit"
              className="btn-primary form-control"
            >
              Register And Pay Now
            </button>
            <p>*Enrollment charges <strong>&#8377; 149</strong></p>
          </form>
        </section>
      ) : !loggedInData ? (
        <section id="registration-form" className="">
          <form id="anthe-lead" accept-charset="UTF-8">
              <input
                type="text"
                name="mobile_no"
                disabled={show}
                value={data.mobile_no}
                size="60"
                maxlength="11"
                placeholder="Enter Your Mobile Number"
                required="required"
                className="form-control"
                aria-required="true"
                onChange={handleChange}
              />

              {!show && !loggedInData ? (
                  <button
                    type="submit"
                    className="form-control btn-primary"
                    onClick={handleSubmit}
                  >
                    GO
                  </button>
              ) : (
                ""
              )}

            {show && !loggedInData ? (
              <div>
                <input
                  id="otp"
                  title=""
                  type="text"
                  className="form-control"
                  name="otp"
                  value={verifyData.otp}
                  size="60"
                  maxlength="5"
                  placeholder="OTP"
                  onChange={handleChange1}
                />
              </div>
            ) : (
              ""
            )}

            {show && !loggedInData  ? (
              <div id="edit-actions">
                <button
                  type="submit"
                  className="form-control btn-primary"
                  onClick={handleSubmit1}
                >
                  Verify OTP
                </button>
              </div>
            ) : (
              ""
            )}
            <div className="already-registered">
              Already Registered? &nbsp; <Link to="/login"> Login now</Link>
            </div>

            <input autocomplete="off" type="hidden" name="form_build_id" />
            <input type="hidden" name="form_id" value="anthe_lead" />
          </form>
        </section>
      ) : (
        ""
      )}
    </div>
  );
}

export default Register;
