import React from "react";
import "./Resourse.css";
import resourceWeb from "../../images/200kb/resource.png";
import resourceMobile from "../../images/resourceMobile.png";
import { ResourceDataLogic } from "./components/ResourceItems";
import ResourceCard from "./components/ResourceCard";

function Resourse() {
  const { ResourceItems } = ResourceDataLogic();

  return (
    <div>
      <section id="resource-banner">
        <img
          alt="Mantra"
          className="resource-web-banner"
          src={resourceWeb}
          style={{ width: "100%", borderRadius: "0rem" }}
        />

        <img
          alt="Mantra"
          className="resource-mobile-banner"
          src={resourceMobile}
          style={{
            width: "100%",
            margin: "0rem 0rem 0rem 0rem",
            height: "20rem",
            borderRadius: "0rem",
          }}
        />
      </section>

      <div className="all-cards">
        {ResourceItems.map((item) => {
          return (
            <ResourceCard
              cardTitle={item.cardTitle}
              cardText={item.cardText}
              CardIcon={item.CardIcon}
              cardLink={item.cardLink}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Resourse;
