import React from 'react'
import './Syllabus.css'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import syllabusWeb from '../../images/200kb/syllabus.png';
import syllabusMobile from '../../images/syllabusMobile.png'


import { examPattern, syllabus,images } from './SyllabusData';

function Syllabus() {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  return (
    <div className='syllabus-container'>


      <section id="syllabus-banner">
        <img alt="Mantra" className="syllabus-web-banner"
          src={syllabusWeb}
          style={{ width: "100%", borderRadius: "0rem" }} />

        <img alt="Mantra" className="syllabus-mobile-banner"
          src={syllabusMobile}
          style={{ width: "100%", margin: "0rem 0rem 0rem 0rem", height: "20rem", borderRadius: "0rem" }} />
      </section>

      <div className="accordion">
        
        {examPattern.map((item) => {
          return (

        <Accordion className="accordion-details" style={{ margin: "2rem 0" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            style={{ backgroundColor: "#b3ccfa" }}
          >
            <Typography variant="h6">{item.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              {item.pattern.map(field => {
                return (
              <Grid item xs={6}>
                <Item>
                  <Typography variant="h5" style={{ color: "black", fontWeight: "800" }}>
                    {field.title}
                  </Typography>
                  <Typography style={{ color: "black", fontWeight: "400" }}>
                    {field.value}
                  </Typography>
                </Item>
              </Grid>
                )
              })}
            </Grid>
          </AccordionDetails>
        </Accordion>
          )
        })}

        {syllabus.map((item) => {
          return (
            <Accordion className="accordion-details" style={{ margin: "2rem 0" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            // style={{ boxShadow: " 2px 2px #000" }}
            >
              <Typography variant="h5" sx={{textTransform:'uppercase'}} >SYLLABUS FOR CLASS {item.class} STUDYING {item?.passed && '& PASSED'} STUDENTS {item?.aspirants && `(${item?.aspirants} Aspirants)`}</Typography>
            </AccordionSummary>
            <AccordionDetails className='details-syllabus'>
              <Stack spacing={2} className="stack-circles" sx={{ width: "20%", justifyContent: "space-evenly" }}>
                {item.subjects.map((sub) => {
                  return (
                    <Item className='stack-circle'>
                      <img src={images[sub.subject]} alt="" srcset="" />
                    </Item>
                  )
                })}
              </Stack>
              <Stack spacing={10} className='stack-details' >
                {item.subjects.map((sub) => {
                  return (
                    <Item >
                  <Item className='stack-circle-mobile'>
                    <img src={images[sub.subject]} alt="" srcset="" />
                  </Item>
                  <Typography variant="h8" style={{ color: "black", fontWeight: "800" }}>
                    {sub.subject} : {sub.chapters.join(' | ')}
                  </Typography>
                </Item>
                  )
                })}
              </Stack>
            </AccordionDetails>
          </Accordion>
          )})}
      </div>
    </div>
  )
}

export default Syllabus
