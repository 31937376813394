import React from 'react'

import './Scholarship.css'
import scholarshipWeb from '../../images/200kb/scholarship.png';
import scholarshipMobile from '../../images/scholarshipMobile.png'

function Scholarship() {

    return (
        <>
            <section id="scholarship-banner">
                <img alt="Mantra" className="scholarship-web-banner"
                    src={scholarshipWeb}
                    style={{ width: "100%", borderRadius: "0rem" }} />

                <img alt="Mantra" className="scholarship-mobile-banner"
                    src={scholarshipMobile}
                    style={{ width: "100%", margin: "0rem 0rem 0rem 0rem", height: "19rem", borderRadius: "0rem" }} />
            </section>
            <div className="container">


                <center className="center-content">
                    <h3>Scholarships for Class VI to XII Studying Students</h3>
                    
                    <p>Top 10 ranking students in each class will also be eligible for Scholarship*.<br /></p>

                    {/* <p><strong>TABLE-1</strong></p> */}

                    <table className="body-table">
                        <tbody><tr>
                            <th>Rank</th>
                            <th>Class-VI</th>
                            <th>Class-VII</th>
                            <th>Class-VIII</th>
                            <th>Class-IX</th>
                            <th>Class-X</th>
                            <th>Class-XI</th>
                            <th>Class-XII</th>
                        </tr>
                            <tr><td ><strong>RANK-1</strong></td>
                                <td> <span style={{color: "red"}} >TAB +</span> <br/> ₹10,000 cash</td>
                                <td> <span style={{color: "red"}} >TAB +</span> <br/> ₹10,000 cash</td>
                                <td> <span style={{color: "red"}} >TAB +</span> <br/> ₹10,000 cash</td>
                                <td> <span style={{color: "red"}} >TAB +</span> <br/> ₹12,000 cash</td>
                                <td> <span style={{color: "red"}} >TAB +</span> <br/> ₹15,000 cash</td>
                                <td> <span style={{color: "red"}} >TAB +</span> <br/> ₹18,000 cash</td>
                                <td> <span style={{color: "red"}} >TAB +</span> <br/> ₹20,000 cash</td>
                            </tr>
                            <tr><td ><strong>RANK-2</strong></td>
                                <td> <span style={{color: "red"}} >MOBILE + </span> <br/> ₹8,000 cash</td>
                                <td> <span style={{color: "red"}} >MOBILE + </span> <br/> ₹8,000 cash</td>
                                <td> <span style={{color: "red"}} >MOBILE + </span> <br/> ₹8,000 cash</td>
                                <td> <span style={{color: "red"}} >MOBILE + </span> <br/> ₹10,000 cash</td>
                                <td> <span style={{color: "red"}} >MOBILE + </span> <br/> ₹12,000 cash</td>
                                <td> <span style={{color: "red"}} >MOBILE + </span> <br/> ₹15,000 cash</td>
                                <td> <span style={{color: "red"}} >MOBILE + </span> <br/> ₹17,000 cash</td>
                            </tr>
                            <tr><td ><strong>RANK-3</strong></td>
                                <td> <span style={{color: "red"}} >MOBILE + </span> <br/> ₹7,500 cash</td>
                                <td> <span style={{color: "red"}} >MOBILE + </span> <br/> ₹7,500 cash</td>
                                <td> <span style={{color: "red"}} >MOBILE + </span> <br/> ₹7,500 cash</td>
                                <td> <span style={{color: "red"}} >MOBILE + </span> <br/> ₹8,500 cash</td>
                                <td> <span style={{color: "red"}} >MOBILE + </span> <br/> ₹10,500 cash</td>
                                <td> <span style={{color: "red"}} >MOBILE + </span> <br/> ₹13,500 cash</td>
                                <td> <span style={{color: "red"}} >MOBILE + </span> <br/> ₹15,500 cash</td>
                            </tr>
                            <tr><td ><strong>RANK-4</strong></td>
                                <td> <span style={{color: "red"}} >MOBILE + </span> <br/> ₹7,000 cash</td>
                                <td> <span style={{color: "red"}} >MOBILE + </span> <br/> ₹7,000 cash</td>
                                <td> <span style={{color: "red"}} >MOBILE + </span> <br/> ₹7,000 cash</td>
                                <td> <span style={{color: "red"}} >MOBILE + </span> <br/> ₹8,000 cash</td>
                                <td> <span style={{color: "red"}} >MOBILE + </span> <br/> ₹8,000 cash</td>
                                <td> <span style={{color: "red"}} >MOBILE + </span> <br/> ₹11,000 cash</td>
                                <td> <span style={{color: "red"}} >MOBILE + </span> <br/> ₹13,000 cash</td>
                            </tr>
                            <tr><td ><strong>RANK-5</strong></td>
                                <td> <span style={{color: "red"}} >MOBILE + </span> <br/> ₹7,000 cash</td>
                                <td> <span style={{color: "red"}} >MOBILE + </span> <br/> ₹7,000 cash</td>
                                <td> <span style={{color: "red"}} >MOBILE + </span> <br/> ₹7,000 cash</td>
                                <td> <span style={{color: "red"}} >MOBILE + </span> <br/> ₹8,000 cash</td>
                                <td> <span style={{color: "red"}} >MOBILE + </span> <br/> ₹8,000 cash</td>
                                <td> <span style={{color: "red"}} >MOBILE + </span> <br/> ₹11,000 cash</td>
                                <td> <span style={{color: "red"}} >MOBILE + </span> <br/> ₹13,000 cash</td>
                            </tr>
                            <tr><td ><strong>RANK-6</strong></td>
                                <td >₹7,000 cash</td>
                                <td >₹7,000 cash</td>
                                <td >₹7,000 cash</td>
                                <td >₹8,000 cash</td>
                                <td >₹8,000 cash</td>
                                <td >₹11,000 cash</td>
                                <td >₹13,000 cash</td>
                            </tr>
                            <tr><td ><strong>RANK-7</strong></td>
                                <td >₹7,000 cash</td>
                                <td >₹7,000 cash</td>
                                <td >₹7,000 cash</td>
                                <td >₹8,000 cash</td>
                                <td >₹8,000 cash</td>
                                <td >₹11,000 cash</td>
                                <td >₹13,000 cash</td>
                            </tr>
                            <tr><td ><strong>RANK-8</strong></td>
                                <td >₹7,000 cash</td>
                                <td >₹7,000 cash</td>
                                <td >₹7,000 cash</td>
                                <td >₹8,000 cash</td>
                                <td >₹8,000 cash</td>
                                <td >₹11,000 cash</td>
                                <td >₹13,000 cash</td>
                            </tr>
                            <tr><td ><strong>RANK-9</strong></td>
                                <td >₹7,000 cash</td>
                                <td >₹7,000 cash</td>
                                <td >₹7,000 cash</td>
                                <td >₹8,000 cash</td>
                                <td >₹8,000 cash</td>
                                <td >₹11,000 cash</td>
                                <td >₹13,000 cash</td>
                            </tr>
                            <tr><td ><strong>RANK-10</strong></td>
                                <td >₹7,000 cash</td>
                                <td >₹7,000 cash</td>
                                <td >₹7,000 cash</td>
                                <td >₹8,000 cash</td>
                                <td >₹8,000 cash</td>
                                <td >₹11,000 cash</td>
                                <td >₹13,000 cash</td>
                            </tr>

                        </tbody></table>
                </center>
                <p style={{textAlign:'right',width:'90%',fontWeight:'500'}}>(*T & C Apply)</p>
            </div >

        </>

    )
}

export default Scholarship