import {  useEffect } from "react";
import { useParams } from "react-router-dom"

import "react-toastify/dist/ReactToastify.css";

//IMPORT REDUX
import { getSingleBlog } from "../../../redux/actions/blogAction";
import { useDispatch, useSelector } from "react-redux";


export const SingleBlogLogic = () => {
    const dispatch = useDispatch();
    const {
        data
      } = useSelector((state) => state.getSingleBlog);


      console.log('====================================');
      console.log(data);
      console.log('====================================');

      const {blogSlug} = useParams();
      

    useEffect(() => {
        dispatch(getSingleBlog(blogSlug));
      }, [dispatch,blogSlug]);
      document.title = (data && data.body.title) || "React App"
    
    return {
        data,
    }
}