import React from "react";
import Banner from "./components/Banner";
import "./Home.css";
import Highlights from "../../Components/Highlights/Highlights";
import Info from "./components/Info/Info";
import HowToRegister from "./components/HowToRegister";
import QA from "./components/QA";
import Register from "../../Components/Register/Register";
import useRegistrationLogic from "../../Components/Register/RegistrationLogic";
import Loading from "../../Components/Loading/Loading";
import Video from "../../Components/Video/Video";

import PeopleIcon from "@mui/icons-material/People";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

function Home() {
  const { otpsentLoading, verifyOtpLoading, verifyDataLoading } =
    useRegistrationLogic();

  return (
    <>
      {otpsentLoading || verifyOtpLoading || verifyDataLoading ? (
        <Loading />
      ) : (
        ""
      )}
      <Banner />
      <Register />
      <Video embedId={"F27ztPdFxTU"} />
      <Highlights />
      <div className="counterSection">
        <div className="counter-bg">
          <PeopleIcon fontSize="large" />
          <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
            {({ isVisible }) => (
              <h1 style={{ fontSize: "3rem" }}>
                {isVisible ? <CountUp end={182000} /> : 182000} +
              </h1>
            )}
          </VisibilitySensor>
          <h6 style={{ color: "#667085" }}>Students Already Registered</h6>
        </div>
      </div>
      <HowToRegister />
      <Info />
      <QA />
    </>
  );
}

export default Home;
