import {  useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


//IMPORT REDUX
import { getAllBlogs } from "../../redux/actions/blogAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const BlogLogic = () => {

    
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { blogs } = useSelector((state) => state.getAllBlogs);

console.log('====================================');
console.log(blogs);
console.log('====================================');
  useEffect(() => {
    dispatch(getAllBlogs());
  }, [dispatch]);




//   
const redirectHandler = (slug) =>{
    navigate(`/blog/${slug}`)
}


// 
const copyHandler = (slug) => {
  navigator.clipboard.writeText(`${process.env.REACT_APP_BLOG_URL}/${slug}`);
  toast.success("Link Copied to clipboard", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  setTimeout(() => {
    dispatch(getAllBlogs());
  }, 1000);
}
  return {
    blogs,
    redirectHandler,
    copyHandler
  };
};
