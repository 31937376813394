import axios from 'axios';
import {
    OTP_REQUEST,
    OTP_SUCCESS,
    OTP_FAILED,
    VERIFY_OTP_REQUEST,
    VERIFY_OTP_SUCCESS,
    VERIFY_OTP_FAILED,
    REGISTRATION_REQUEST,
    REGISTRATION_SUCCESS,
    REGISTRATION_FAILED,
    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAILED,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    USER_LOGOUT,
    OTP_VERIFY_FAILED,
    OTP_VERIFY_REQUEST,
    OTP_VERIFY_SUCCESS

} from '../constants/userConstants';

const API = process.env.REACT_APP_NODE_API;
//const API = 'http://159.65.156.114'
//  console.log(API);
export const otpUser = (userData) => async (dispatch) => {
    try {
        dispatch({
            type: OTP_REQUEST
        })
        const config = {
            'Content-Type': 'application/json'

        }

        const user = await axios({
            method: "post",
            url: `${API}/public/student/auth/register`,
            data: userData,
            config
        })
        dispatch({
            type: OTP_SUCCESS,
            payload: user
        })
    } catch (error) {

        dispatch({
            type: OTP_FAILED,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message
        })

    }
}

///    OTP VERIFY    ///
export const EmailVerification = (otp,id) => async (dispatch) => {
    try {
      dispatch({
        type: OTP_VERIFY_REQUEST
      })
  
      const config = {
        'Content-Type': 'application/json'
      }
  
      const body = {
        otp:otp
      }
  
      const { data } = await axios.put(`${API}/users/email-confirmation/${id}`, body, config)
  
      dispatch({
        type: OTP_VERIFY_SUCCESS,
        payload: data
      })
    } catch (error) {
      dispatch({
        type: OTP_VERIFY_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }

export const verifyOtp = (userData) => async (dispatch) => {

    try {
        dispatch({
            type: VERIFY_OTP_REQUEST
        })

        const config = {
            'Content-Type': 'application/json'
        }

        const verify = await axios({
            method: "post",
            url: `${API}/public/student/auth/verify-otp`,
            data: userData,
            config
        })

        dispatch({
            type: VERIFY_OTP_SUCCESS,
            payload: verify
        })
    } catch (error) {

        dispatch({
            type: VERIFY_OTP_FAILED,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message
        })
    }
}

export const register = (userData) => async (dispatch) => {
    try {
        dispatch({
            type: REGISTRATION_REQUEST
        })


        const config = {
            'Content-Type': 'application/json'
        }

        const {data} = await axios({
            method: "post",
            url: `${API}/public/student/auth/complate-registration`,
            data: userData,
            config
        })

        localStorage.setItem('userInfo', JSON.stringify(data))

        dispatch({
            type: REGISTRATION_SUCCESS,
            payload: data
        })

    } catch (error) {

        dispatch({
            type: REGISTRATION_FAILED,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message
        })
    }
}

export const userDetails = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_DETAILS_REQUEST
        })

        const {
            userSignin: { userInfo },
            userSignup: { userInfo:getUserId }
        } = getState()

        const userId = userInfo || getUserId


        const config = {
            'Content-Type': 'application/json'
        }

        const {data} = await axios.get(`${API}/private/student/activity/profile/${userId.data._id}`,
            config)

        dispatch({
            type: USER_DETAILS_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: USER_DETAILS_FAILED,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message
        })
    }
}

export const login = (userData) => async (dispatch) => {
    try {
        dispatch({
            type: LOGIN_REQUEST
        });
        const config = {
            'Content-Type': 'application/json'
        }

        const {data} = await axios({
            method: "post",
            url: `${API}/public/student/auth/login`,
            data: userData,
            config
        });
        localStorage.setItem('userInfo', JSON.stringify(data))
        dispatch({
            type: LOGIN_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: LOGIN_FAILED,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message
        })

    }
}


  // user logout
  export const logoutUser = () => (dispatch) => {
    if (localStorage.getItem('userInfo')) {
      localStorage.removeItem('userInfo')
    }
    dispatch({
      type: USER_LOGOUT
    })
  }

  //upload file
  export const uploadFile ={
    uploadFile:{url:'/file/upload',method:'POST'}
  }