import axios from "axios";

//ts-check
const config = {
  "Content-Type": "application/json",
};
const API = process.env.REACT_APP_NODE_API;

/**\
 * @param {Object} user
 * @param {{
 * videoSolutionStatus: boolean,
 * testimonialStatus: boolean,
 * }} updatedStatus
 * @returns {Promise<{data: null}>}
 */
export const updateStudentActivityStatus = async (user, updatedStatus) => {
  if (user) {
    const url = `${API}/private/student/activity/update-video-status/${
      user && user.data._id
    }`;
    const { data } = await axios.post(url, updatedStatus, config);
    return data;
  }
  return { data: null };
};
