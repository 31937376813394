import React from 'react'
import {QA as QAData} from './qaData' 

function QA() {
  return (
    <div className='info-wrapper padding-default'>
        <h2>Q&A</h2>
        <div>
            {QAData.map(qa => (
                <div className='qa-card'>
                    <p className='qa-question'>Q. {qa.question}</p>
                    <p>A. {qa.answer}</p>
                </div>
            ))}
        </div>
    </div>
  )
}

export default QA