import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import "./Dashboard.css";
import sampleQuestionPaper from "../../file/SAMPLE-QUESTION-PAPER.pdf";
import BROCHURE from "../../file/BROCHURE.pdf";
import { userDetails } from "../../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux/es/exports";
import PaymentCard from "../PaymentCard";
import mantraLogo from "../../assets/MantraLogo.png";
import Swal from "sweetalert2";

import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { progressBarSteps } from "./ProgressBarSteps";
import { convertTime12to24 } from "./convertTimeFrom12To24";
import { Button } from "@mui/material";

function Dashbord() {
  const [timerData, setTimerData] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    timesUp: false,
    examTimeUp : false,
  });
  const dispatch = useDispatch();
  const { data: user } = useSelector((state) => state.userDetailsReducer);
  const [progress, setProgress] = useState(2);
  const [percent, setPercent] = useState(0);
  const { REACT_APP_MOCK_TEST_API, REACT_APP_MOCK_TEST_FRONTEND,
    REACT_APP_MAIN_TEST_API, REACT_APP_MAIN_TEST_FRONTEND,
  } = process.env;

  useEffect(() => {
    dispatch(userDetails());
  }, [dispatch]);

  useEffect(() => {
    if (user && user && user.data.videoSolutionStatus === true) {
      setProgress(3);
    }
    if (
      user &&
      user &&
      user.data.videoSolutionStatus === true &&
      user.data.testimonialStatus === true
    ) {
      setProgress(4);
    }
    setTimeout(() => {
      handleProgress();
    }, 1000);
    // eslint-disable-next-line
  }, [user]);

  const testLinkRedirect = async (isMockTest = false) => {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      let testBaseUrl = REACT_APP_MAIN_TEST_API
      if(isMockTest)
       testBaseUrl = REACT_APP_MOCK_TEST_API

       console.log(testBaseUrl);
       const [arrayOfSubjectIds, arrayOfClassIds] = await Promise.all([
        axios.get(
          `${testBaseUrl}/private/class-stream/get-class-stream`,
          config
        ),
        axios.get(
          `${testBaseUrl}/private/subjects/get-subjects-by-class-stream?classAndStream=${
            user && user.data.classAndStream
          }`
        ),
      ]);

      // const arrayOfSubjectIds = await axios.get(
      //   `${testBaseUrl}/private/class-stream/get-class-stream`,
      //   config
      // );
      // const arrayOfClassIds = await axios.get(
      //   `${testBaseUrl}/private/subjects/get-subjects-by-class-stream?classAndStream=${
      //     user && user.data.classAndStream
      //   }`
      // );
      const subjectId = arrayOfSubjectIds.data.subjects.find(
        (subject) => subject.name === (user && user.data.classAndStream)
      )._id;

      const classId =
        arrayOfClassIds.data.subjectsWithClassAndStream[0] &&
        arrayOfClassIds.data.subjectsWithClassAndStream[0]._id;

        let frontEndTestLink = REACT_APP_MAIN_TEST_FRONTEND;

        if(isMockTest)
          frontEndTestLink = REACT_APP_MOCK_TEST_FRONTEND


      const redirectLink = `${frontEndTestLink}/?userId=${
        user && user.data._id
      }&classAndStream=${
        user && user.data.classAndStream
      }&classId=${classId}&subject=${subjectId}&subjectName=physics`;

      window.open(redirectLink, "_blank").focus();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: 'Something went wrong! Please contract adminrator.(Possible PRoblem your subject or class has not been created yet)',
      });
    }
  };

  // commented because  it has been made dynamic
  // const mockTest = () => {
  //   Swal.fire({
  //     icon: "info",
  //     title: "Coming soon !!",
  //   });
  // };

  const handleProgress = () => {
    if (progress === 1) {
      setPercent(0);
    } else if (progress === 2) {
      setPercent(25);
    } else if (progress === 3) {
      setPercent(50);
    } else if (progress === 4) {
      setPercent(75);
    } else {
      setPercent(100);
    }
  };

  /**
   *
   * @param {Date} date
   * @param {String} time
   * @example date: "9th December, 2022"
   *          time: "12 PM, Online"
   */
  function comingSoon() {
    const date = user ? user.data.ExamDate : "9th December, 2022";
    const timeAndMode = user && user.data.examModeAndTime;
    const timeinTwelveHourFormat = timeAndMode && timeAndMode.split(",")[0];
    const timeinTwentyFourHourFormat = convertTime12to24(
      timeinTwelveHourFormat
    );

    // Expected output: 9 December, 2022
    const newDate = date.replace(/th|st|rd/g, "");

    // convert newDate to milliseconds
    const dateToMs = new Date(newDate).getTime();
    const deadline =
      dateToMs + parseInt(timeinTwentyFourHourFormat) * 60 * 60 * 1000;

    let now = new Date().getTime();
    let t = deadline - now;
    let dd = Math.floor(t / (1000 * 60 * 60 * 24));
    let hh = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let mm = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
    let ss = Math.floor((t % (1000 * 60)) / 1000);

    let days = dd < 10 ? 0 + dd : dd;
    let hours = hh < 10 ? 0 + hh : hh;
    let minutes = mm < 10 ? 0 + mm : mm;
    let seconds = ss < 10 ? 0 + ss : ss;

    // set the values in the state
    setTimerData({
      days,
      hours,
      minutes,
      seconds,
    });
    if (t < 0) {
      clearInterval(end);
      setTimerData({ timesUp: true, examTimeUp: (t && t < -3600000) ? true : false });
    }
  }
  var end = setInterval(comingSoon, 1000);


  return (
    <div className="flex">
      <SideBar />
      <div style={{ width: "100%" }}>
        <div>
          {/* <div className='loader'>
            <h1>Exam Preparation</h1>

            <img src={step2} alt="" width="100%" style={{ margin: "1rem 0" }} />
          </div> */}
          <div className="loader">
            <h1 style={{ margin: "1rem 0 4rem 0" }}>Exam Preparation</h1>
            <div style={{ width: "95%" }}>
              <ProgressBar percent={percent} filledBackground="#456d93">
                {progressBarSteps.map((step, index) => (
                  <Step key={index}>
                    {({ accomplished }) => (
                      <div className="bar">
                        <Link
                          to={
                            percent < step.percentageRequired ? "" : step.link
                          }
                          style={{ textDecoration: "none" }}
                        >
                          <div
                            className={`indexedStep ${
                              accomplished ? "accomplished" : ""
                            }`}
                          />
                        </Link>
                        <Link
                          to={
                            percent < step.percentageRequired ? "" : step.link
                          }
                          style={{ textDecoration: "none" }}
                        >
                          <div className="label">{step.label}</div>
                        </Link>
                      </div>
                    )}
                  </Step>
                ))}
              </ProgressBar>
            </div>
          </div>
          <div className="countdown">
            
            {timerData.timesUp ? (
              <div>
              {timerData.examTimeUp ?  (
                  <div style={{fontWeight: '600', color: "#D3E5FF", fontSize: '3rem' }}>Your exam is Over.</div>)
                  :
               (<div>
                <h2 style={{ color: "#D3E5FF" }}>Your exam time is here. You can now start your exam.Best of Luck !!</h2>
                <Button variant="outlined" size="large" style={{marginTop: "0.9rem", color: "#f0f0f0" , outline: "#f0f0f0", border: '#f0f0f0 1px solid'}} onClick={() => testLinkRedirect(false)}>Start Exam</Button>
                </div>
                )}
              </div>
              ) : (
              <div>
              <h3 style={{ color: "#D3E5FF" }}>Your Exam is in...</h3>
              <div className="days">
                <div>
                  {timerData.days}
                  <span className="font">Days</span>
                </div>
                <div>
                  {timerData.hours}
                  <span className="font">Hours</span>
                </div>
                <div>
                  {timerData.minutes}
                  <span className="font">Min</span>
                </div>
                <div>
                  {timerData.seconds}
                  <span className="font">Sec</span>
                </div>
              </div>
              </div>
            )}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="h1">Devising Kit</h1>

          {user && user.data.isPaid ? (
            <div className="cards">
              <div className="card card2">
                <img
                  src={mantraLogo}
                  style={{ background: "#16375c", height: "15rem" }}
                  className="card-img-top"
                  alt={mantraLogo}
                />
                <div
                  className="card-body"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "left",
                  }}
                >
                  <h5 className="card-title">Brochure</h5>
                  <p className="card-text">
                    To know more about MANTRA-2022 and it's T&C, Download the
                    Brochure. Best of luck for your exam.
                  </p>
                  <a
                    href={BROCHURE}
                    download="MANTRA-BROCHURE"
                    className="btn btn-primary"
                    style={{ marginTop: "0.5rem" }}
                  >
                    Download
                  </a>
                </div>
              </div>
              <div className="card card2">
                <img
                  src={mantraLogo}
                  style={{ background: "#16375c", height: "15rem" }}
                  className="card-img-top"
                  alt={mantraLogo}
                />
                <div
                  className="card-body"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "left",
                  }}
                >
                  <h5 className="card-title">Sample Question Paper</h5>
                  <p className="card-text">
                    To prepare your self for MANTRA-2022, Download the Sample
                    Question Paper. Best of luck for your exam.
                  </p>
                  <a
                    href={sampleQuestionPaper}
                    download="SAMPLE-QUESTION-PAPER"
                    className="btn btn-primary"
                    style={{ marginTop: "0.5rem" }}
                  >
                    Download
                  </a>
                </div>
              </div>
              <div className="card card2">
                <img
                  src={mantraLogo}
                  style={{ background: "#16375c", height: "15rem" }}
                  className="card-img-top"
                  alt={mantraLogo}
                />
                <div
                  className="card-body"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "left",
                  }}
                >
                  <h5 className="card-title">Mock Test-01 MANTRA-2022</h5>
                  <p className="card-text">Coming Soon !!</p>
                  {/*{user && user.data.mockTestStatus === "In Progress" ? (
                    <button
                      onClick={mockTestRedirect}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span
                        className="btn btn-primary"
                        style={{ marginTop: "0.5rem" }}
                      >
                        Resume Test
                      </span>
                    </button>
                  ) : (
                    <button
                      onClick={mockTestRedirect}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span
                        className="btn btn-primary"
                        style={{ marginTop: "0.5rem" }}
                      >
                        Start Test
                      </span>
                    </button>
                  )}*/}
                  {<span
                    className="btn btn-primary"
                    onClick={() => testLinkRedirect(true)}
                    style={{ marginTop: "0.5rem" }}
                  >
                    Start Test
                  </span>}
                </div>
              </div>
            </div>
          ) : (
            <PaymentCard />
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashbord;
