import Swal from "sweetalert2";
import React, { useEffect, useState } from "react";
import { register } from "../../redux/actions/userAction";
import {useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import pic from '../../images/homeWeb.svg'
import pic1 from '../../images/IMG-20220827-WA0013.jpg'

function Admin() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userInfo: registerData} = useSelector(
        (state) => state.userSignup
      );
    useEffect(() => {
        if (registerData) {
          Swal.fire({
            icon: "success",
            title: "Registered successfully",
          });
          navigate("/dashboard");
        }
      }, [registerData, navigate]);
    const [user, setUser] = useState({
        name: "",
        guardian: "",
        email: "",
        mobile_no: "",
        date: "",
        class: "",
        mode: "",
        exam: "",
        homeTown: "",
        state: "",
        referral: ""
    });

    const handleChange2 = (e) => {
        setUser({ ...user, [e.target.name]: [e.target.value] });
        // console.log(user);
    };
    console.log(user.class.toString(), user.mode.toString(), user.exam.toString());
    const handleSubmit2 = (e) => {
        e.preventDefault();

        if (user.exam.toString() === "Booked") {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "All seats are booked, for that date",
            });
            return;
        }
        if (!user.state.toString() || !user.class.toString() || !user.mode.toString() || !user.exam.toString() || !user.homeTown.toString() || !user.name.toString() || !user.guardian.toString() || !user.email.toString() || !user.date.toString() || !user.referral.toString()) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Please select all the fields",
            });
            return;
          }      

        const userData = {
            stundetName: user.name.toString(),
            mobileNumber: user.mobile_no.toString(),
            studentEmail: user.email.toString(),
            homeTown: user.homeTown.toString(),
            state: user.state.toString(),
            GuardianName: user.guardian.toString(),
            dateOfBirth: user.date.toString(),
            classAndStream: user.class.toString(),
            examModeAndTime: user.mode.toString(),
            // District: user.district.toString(),
            ExamDate: user.exam.toString(),
            referral: user.referral.toString(),
            paymentMethod: "cash"
        };
        dispatch(register(userData));

    }

    return (
        <div style={{marginBottom: "1rem"}}>
         <section id="banner">
          <img
            alt="Mantra"
            className="web-banner"
            src={pic}
            style={{
              margin: "0rem 0rem 0rem 0rem",
              height: "100%",
              width: "100%",
              borderRadius: "0rem",
              backgroundSize: "100% 100%",
            }}
          />

          <img
            alt="mantra"
            className="mobile-banner"
            src={pic1}
            style={{
              width: "100%",
              margin: "0rem 0rem 0rem 0rem",
              height: "20rem",
              borderRadius: "0rem",
            }}
          />
        </section>
            <section className="lead-form-block2">
                <form
                    className="anthe-lead"
                    onSubmit={handleSubmit2}
                    accept-charset="UTF-8"
                >
                    <h4>Registration</h4>

                    <div className="name-input">
                        <input
                            className="name"
                            id="name"
                            type="text"
                            name="name"
                            value={user.name}
                            size="60"
                            placeholder="Student Name"
                            onChange={handleChange2}
                        />
                    </div>

                    <div className="name-input">
                        <input
                            className="guardian-name"
                            id="guardian-name"
                            type="text"
                            name="guardian"
                            value={user.guardian}
                            size="60"
                            placeholder="Guardian Name"
                            onChange={handleChange2}
                        />
                    </div>

                    <div className="email-input">
                        <input
                            className="email"
                            id="homeTown"
                            type="text"
                            name="homeTown"
                            value={user.homeTown}
                            size="60"
                            placeholder="Home Town/City"
                            onChange={handleChange2}
                        />
                    </div>

                    <div className="examdate-input" style={{ marginTop: '2rem' }}>
                        {/* <label>Exam Mode & Time:</label> */}
                        {/* <br />
                  <p style={{ fontWeight: "600" }}>10 AM, Online</p> */}
                        <select
                            className="form-control"
                            name="state"
                            onChange={handleChange2}
                        >
                            <option value="select">Select  State</option>
                            <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands	</option>
                            <option value="Andhra Pradesh">Andhra Pradesh	</option>
                            <option value="Arunachal Pradesh">Arunachal Pradesh	</option>
                            <option value="Assam">Assam	</option>
                            <option value="Bihar">Bihar	</option>
                            <option value="Chandigarh">Chandigarh</option>
                            <option value="Chhattisgarh">Chhattisgarh	</option>
                            <option value="Dadra & Nagar Haveli and Daman & Diu">Dadra & Nagar Haveli and Daman & Diu		</option>
                            <option value="Delhi">Delhi		</option>
                            <option value="Goa">Goa	</option>
                            <option value="Gujarat">Gujarat	</option>
                            <option value="Haryana">Haryana	</option>
                            <option value="Himachal Pradesh">Himachal Pradesh	</option>
                            <option value="Jammu and Kashmir">Jammu and Kashmir	</option>
                            <option value="Jharkhand">Jharkhand	</option>
                            <option value="Karnataka">Karnataka	</option>
                            <option value="Kerala">Kerala	</option>
                            <option value="Ladakh">Ladakh</option>
                            <option value="Lakshadweep">Lakshadweep</option>
                            <option value="Madhya Pradesh">Madhya Pradesh	</option>
                            <option value="Maharashtra">Maharashtra	</option>
                            <option value="Manipur">Manipur	</option>
                            <option value="Meghalaya">Meghalaya	</option>
                            <option value="Mizoram">Mizoram	</option>
                            <option value="Nagaland">Nagaland	</option>
                            <option value="Odisha">Odisha	</option>
                            <option value="Puducherry">Puducherry</option>
                            <option value="Punjab">Punjab	</option>
                            <option value="Rajasthan">Rajasthan	</option>
                            <option value="Sikkim">Sikkim	</option>
                            <option value="Tamil Nadu">Tamil Nadu	</option>
                            <option value="Telangana">Telangana	</option>
                            <option value="Tripura">Tripura	</option>
                            <option value="Uttar Pradesh">Uttar Pradesh	</option>
                            <option value="Uttarakhand">Uttarakhand	</option>
                            <option value="West Bengal">West Bengal	</option>
                        </select>
                    </div>


                    <div className="email-input">
                        <input
                            className="email"
                            id="email"
                            type="email"
                            name="email"
                            value={user.email}
                            size="60"
                            placeholder="Email"
                            onChange={handleChange2}
                        />
                    </div>
                    <div className="email-input">
                        <input
                            type="text"
                            name="mobile_no"
                            value={user.mobile_no}
                            size="60"
                            maxlength="11"
                            placeholder="Mobile No."
                            required="required"
                            onChange={handleChange2}
                        />
                    </div>
                    <div className="date-input">
                        <input
                            className="date"
                            id="date"
                            type="text"
                            name="date"
                            value={user.date}
                            size="60"
                            onChange={handleChange2}
                            placeholder="Date of Birth"
                            onFocus={(e) => {
                                e.target.type = "date";
                            }}
                        />
                    </div>
                    <div className="stream-input">
                        <select
                            className="form-control"
                            name="class"
                            onChange={handleChange2}
                        >
                            <option value="select">Class & Stream </option>
                            <option value="Class : 6"> Class : 6</option>
                            <option value="Class : 7"> Class : 7</option>
                            <option value="Class : 8"> Class : 8</option>
                            <option value="Class : 9  Foundation">
                                {" "}
                                Class : 9 Foundation
                            </option>
                            <option value="Class : 10 Foundation">
                                {" "}
                                Class : 10 Foundation
                            </option>
                            <option value="Class : 11 Engineering/IIT JEE">
                                {" "}
                                Class : 11 Engineering/IIT JEE
                            </option>
                            <option value="Class : 11 Medical/NEET UG">
                                {" "}
                                Class : 11 Medical/NEET UG
                            </option>
                            <option value="Class : 12 Engineering/IIT JEE">
                                {" "}
                                Class : 12 Engineering/IIT JEE
                            </option>
                            <option value="Class : 12 Medical/NEET UG">
                                {" "}
                                Class : 12 Medical/NEET UG
                            </option>
                            <option value="NEET UG DROPPER"> NEET UG DROPPER</option>
                            <option value="IIT JEE UG DROPPER">
                                {" "}
                                IIT JEE UG DROPPER
                            </option>
                        </select>
                    </div>

                    <div className="examdate-input" style={{ marginTop: '2rem' }}>
                        {/* <label>Exam Mode & Time:</label> */}
                        {/* <br />
                  <p style={{ fontWeight: "600" }}>10 AM, Online</p> */}
                        <select
                            className="form-control"
                            name="mode"
                            onChange={handleChange2}
                        >
                            <option value="select">Exam Mode & Time</option>
                            <option value="10 AM, Online">
                                10 AM, Online
                            </option>
                            <option value="11 AM, Online">
                                11 AM, Online
                            </option>
                            <option value="12 PM, Online">
                                12 PM, Online
                            </option>
                            <option value="01 PM, Online">
                                01 PM, Online
                            </option>
                            <option value="02 PM, Online">
                                02 PM, Online
                            </option>
                            <option value="03 PM, Online">
                                03 PM, Online
                            </option>
                            <option value="04 PM, Online">
                                04 PM, Online
                            </option>
                            <option value="05 PM, Online">
                                05 PM, Online
                            </option>
                            <option value="06 PM, Online">
                                06 PM, Online
                            </option>
                            <option value="07 PM, Online">
                                07 PM, Online
                            </option>
                            <option value="08 PM, Online">
                                08 PM, Online
                            </option>
                            <option value="09 PM, Online">
                                09 PM, Online
                            </option>
                        </select>
                    </div>

                    <div className="examdate-input">
                        <select
                            className="form-control"
                            name="exam"
                            onChange={handleChange2}
                        >
                            <option value="select">Exam Date</option>
                            <option value="Booked">
                                11th November, 2022 (All Slots Booked)
                            </option>
                            <option value="Booked">
                                12th November, 2022 (All Slots Booked)
                            </option>
                            <option value="Booked">
                                13th November, 2022 (All Slots Booked)
                            </option>
                            <option value="03rd December, 2022">
                                3rd December, 2022
                            </option>
                            <option value="Booked">
                                4th December, 2022 (All Slots Booked)
                            </option>
                            <option value="5th December, 2022">
                                5th December, 2022
                            </option>
                            <option value="6th December, 2022">
                                6th December, 2022
                            </option>
                            <option value="7th December, 2022">
                                7th December, 2022
                            </option>
                            <option value="8th December, 2022">
                                8th December, 2022
                            </option>
                            <option value="9th December, 2022">
                                9th December, 2022
                            </option>
                            <option value="10th December, 2022">
                                10th December, 2022
                            </option>
                            <option value="11th December, 2022">
                                11th December, 2022
                            </option>
                        </select>
                    </div>
                    <div className="email-input">
                        <input
                            className="referral"
                            id="referral"
                            type="text"
                            name="referral"
                            value={user.referral}
                            size="60"
                            placeholder="Referred By"
                            onChange={handleChange2}
                            required="required"
                        />
                    </div>
                    <div className="submit">
                        <input
                            type="submit"
                            id="edit-next"
                            name="submit"
                            value="Register"
                            style={{width: "35%"}}
                        />
                    </div>

                    <p className="enrollment" style={{paddingBottom: "1rem"}}>Enrollment charges Rs 149</p>
                </form>
            </section>
        </div>
    )
}
export default Admin;
