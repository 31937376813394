import Mantra from "../../../images/mantra_logo.png";
import HeroSectionImg from "../../../images/hero_section.png";

function Banner() {
  return (
    <div
      className="hero-section__wrapper"
      style={{ backgroundImage: `url(${HeroSectionImg})` }}
    >
      <div className="hero-section__content padding-default">
        <img id="mantra-logo" src={Mantra} />
        <p>MATRICULA NATIONAL TALENT RECOGNITION & ANALYSIS EXAM</p>
        <button onClick={() => {
          const registerForm = document.getElementById('register')
          registerForm.scrollIntoView({behavior: 'smooth', block: 'center'})
        }} id="register-btn" className="btn-secondary">
          Register Now!
        </button>
      </div>
    </div>
  );
}

export default Banner;
