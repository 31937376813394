import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { logoutUser } from "../../redux/actions/userAction";
import navbarLogo from "../../assets/logo.svg";

import "./NavigationBar.css";
import Login from "../Login/Login";
function NavigationBar() {
  const { userInfo } = useSelector((state) => state.userSignin);

  //get the pathname using react-router-dom
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = (e) => {
    //e.preventDefault();
    navigate("/");
    dispatch(logoutUser());
  };

  const handlemove = () => {
    navigate('/login')
  }

  return (
    <div>
      <div
        data-collapse="medium"
        data-animation="default"
        data-duration="400"
        data-easing="ease"
        data-easing2="ease"
        role="banner"
        className="navbar_component-1-4 background-color-white w-nav"
      >
        <div className="navbar1_container">
          <Link to="/" className="navbar1_logo-link w-nav-brand">
            <img
              src={navbarLogo}
              loading="lazy"
              alt=""
              className="navbar_logo-1-1"
              style={{ maxWidth: "auto" }}
            />
          </Link>
          <nav role="navigation" className="navbar1-1_menu w-nav-menu">
            <div className="navbar1-1_menu-left">
              <div
                data-hover="true"
                data-delay="200"
                className="navbar1_menu-dropdown w-dropdown"
              >
                <div className="navbar1-1_dropdown-toggle resources w-dropdown-toggle">
                  <Link to="/" className="navbar_link-1-1 news w-nav-link" style={{ color: "black" }}>
                    Home
                  </Link>
                </div>
              </div>
              <div
                data-hover="true"
                data-delay="200"
                className="navbar1_menu-dropdown w-dropdown"
              >
                <div className="navbar1-1_dropdown-toggle resources w-dropdown-toggle">
                  <Link
                    to="/scholarships-awards"
                    className="navbar_link-1-1 news w-nav-link"
                    style={{ color: "black" }}
                  >
                    Scholarships & Awards
                  </Link>
                </div>
              </div>
              <div
                data-hover="true"
                data-delay="200"
                className="navbar1_menu-dropdown w-dropdown"
              >
                <div className="navbar1-1_dropdown-toggle resources w-dropdown-toggle">
                  <Link to="/blog" className="navbar_link-1-1 news w-nav-link" style={{ color: "black" }}>
                    Blog
                  </Link>
                </div>
              </div>
              <div
                data-hover="true"
                data-delay="200"
                className="navbar1_menu-dropdown w-dropdown"
              >
                <div className="navbar1-1_dropdown-toggle resources w-dropdown-toggle">
                  <Link
                    to="/syllabus"
                    className="navbar_link-1-1 news w-nav-link"
                    style={{ color: "black" }}
                  >
                    Syllabus
                  </Link>
                </div>
              </div>
              <div
                data-hover="true"
                data-delay="200"
                className="navbar1_menu-dropdown w-dropdown"
              >
                <div className="navbar1-1_dropdown-toggle resources w-dropdown-toggle">
                  <p className="navbar_link-1-1 news w-nav-link" onClick={handlemove}>
                    MANTRA Mock Test
                  </p>
                </div>
              </div>
              <div
                data-hover="true"
                data-delay="200"
                className="navbar1_menu-dropdown w-dropdown"
              >
                <div className="navbar1-1_dropdown-toggle resources w-dropdown-toggle">
                  <Link
                    to="/downloads"
                    className="navbar_link-1-1 news w-nav-link"
                    style={{ color: "black" }}
                  >
                    Downloads
                  </Link>
                </div>
              </div>
              <div
                data-hover="true"
                data-delay="200"
                className="navbar1_menu-dropdown w-dropdown"
              >
                <div className="navbar1-1_dropdown-toggle resources w-dropdown-toggle">
                  <Link
                    to="/resource-center"
                    className="navbar_link-1-1 news w-nav-link"
                    style={{ color: "black" }}
                  >
                    Resource Center
                  </Link>
                </div>
              </div>
              <div
                data-hover="true"
                data-delay="200"
                className="navbar1_menu-dropdown w-dropdown"
              >
                <div className="navbar1-1_dropdown-toggle resources w-dropdown-toggle">
                  {userInfo ? (
                    <span
                      onClick={handleLogout}
                      className="navbar_link-1-1"
                      style={{
                        background: "#16375c",
                        color: "#fff",
                        padding: ".5rem 1rem",
                        textDecoration: "none",
                        borderRadius: "2rem",
                      }}
                    >
                      Logout
                    </span>
                  ) : (
                    <Link
                      to="/login"
                      className="navbar_link-1-1"
                      style={{
                        background: "#16375c",
                        color: "#fff",
                        padding: ".5rem 1rem",
                        textDecoration: "none",
                        borderRadius: "2rem",
                      }}
                    >
                      Login
                    </Link>
                  )}

                  {userInfo &&
                    pathname !== "/dashboard" &&
                    pathname !== "/dashboard/" &&
                    pathname !== "/profile" &&
                    pathname !== "/profile/" &&
                    pathname !== "/result" &&
                    pathname !== "/result/" ? (
                    <a
                      href="/dashboard"
                      className="navbar_link-1-1"
                      style={{
                        background: "#16375c",
                        color: "#fff",
                        padding: ".5rem 1rem",
                        textDecoration: "none",
                        borderRadius: "2rem",
                        marginLeft: "2rem",
                      }}
                    >
                      Dashboard
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div
              data-hover="true"
              data-delay="200"
              className="navbar1_menu-dropdown w-dropdown"
            >
              <div className="navbar1-1_dropdown-toggle resources w-dropdown-toggle"></div>
            </div>
          </nav>
          <div className="navbar1_menu-button w-nav-button">
            <div className="menu-icon1">
              <div className="menu-icon1_line-top"></div>
              <div className="menu-icon1_line-middle">
                <div className="menu-icon_line-middle-inner"></div>
              </div>
              <div className="menu-icon1_line-bottom"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <>
    //   <div className="nav-heading">
    //     <Link to="/" className="navbar1_logo-link w-nav-brand">
    //       <img
    //         src={navbarLogo}
    //         loading="lazy"
    //         alt=""
    //         className="navbar_logo-1-1"
    //         style={{ maxWidth: "auto" }}
    //       />
    //     </Link>
    //     <div
    //       data-hover="true"
    //       data-delay="200"
    //       className="navbar1 "
    //     >
    //       <div className="btn-contents">
    //         {userInfo ? (
    //           <span
    //             onClick={handleLogout}
    //             className="navbar_link-1-1"
    //             style={{
    //               background: "#16375c",
    //               color: "#fff",
    //               padding: ".5rem 1rem",
    //               textDecoration: "none",
    //               borderRadius: "2rem",
    //             }}
    //           >
    //             Logout
    //           </span>
    //         ) : (
    //           <Link
    //             to="/login"
    //             className="navbar_link-1-1"
    //             style={{
    //               background: "#16375c",
    //               color: "#fff",
    //               padding: ".5rem 1rem",
    //               textDecoration: "none",
    //               borderRadius: "1rem",
    //             }}
    //           >
    //             Login
    //           </Link>
    //         )}

    //         {userInfo &&
    //         pathname !== "/dashboard" &&
    //         pathname !== "/dashboard/" &&
    //         pathname !== "/profile" &&
    //         pathname !== "/profile/" &&
    //         pathname !== "/result" &&
    //         pathname !== "/result/" ? (
    //           <a
    //             href="/dashboard"
    //             className="navbar_link-1-1"
    //             style={{
    //               background: "#16375c",
    //               color: "#fff",
    //               padding: ".5rem 1rem",
    //               textDecoration: "none",
    //               borderRadius: "2rem",
    //               marginLeft: "1rem",
    //             }}
    //           >
    //             Dashboard
    //           </a>
    //         ) : (
    //           ""
    //         )}
    //       </div>
    //     </div>
    //   </div>
    // </>
  );
}

export default NavigationBar;
