import React, { useEffect, useState } from "react";
import SideBar from "../SideBar/SideBar";
import "./Result.css";
import { userDetails } from "../../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux/es/exports";
import PaymentCard from "../PaymentCard";
import axios from "axios";
function Result() {
  const dispatch = useDispatch();
  const { REACT_APP_NODE_API } = process.env;
  const { data } = useSelector((state) => state.userDetailsReducer);
  const [student_res, setStudent_res] = useState(null);

  const fetchresult = async () => {
    const result = await axios.get(
      `${REACT_APP_NODE_API}/private/student/activity/get-result/${data.data?._id}`
    );
    setStudent_res(result.data?.data);
    console.log(result);
  };

  useEffect(() => {
    dispatch(userDetails());
    fetchresult();
  }, [dispatch, student_res]);

  return (
    <div className="flex">
      <SideBar />
      {data && data.data.isPaid ? (
        <div className="main-body" style={{ width: "100%" }}>
          <div className="col-md-8" style={{ width: "100%" }}>
            <div className="card mb-3">
              <div className="card-body">
                <h3 className="d-flex align-items-center mb-3">
                  Result Details
                </h3>
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Student Name</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">
                    {data && data.data.stundetName}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Exam Date</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">
                    {data && data.data?.ExamDate}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Marks Obtained</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">
                    {student_res && student_res?.marks}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <PaymentCard />
      )}
    </div>
  );
}

export default Result;
