import {
    OTP_REQUEST,
    OTP_SUCCESS,
    OTP_FAILED,
    VERIFY_OTP_REQUEST,
    VERIFY_OTP_SUCCESS,
    VERIFY_OTP_FAILED,
    REGISTRATION_REQUEST,
    REGISTRATION_SUCCESS,
    REGISTRATION_FAILED,
    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAILED,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    USER_LOGOUT,
} from '../constants/userConstants';


export const otpReducer = (state = {
    loading: false,
    data: null,
    error: null
} , action) => {

    switch(action.type) {
        case OTP_REQUEST:
            return {
                ...state,
                data: null,
                error: null,
                loading: true
            }
        case OTP_SUCCESS:
            return {
                ...state,
                data:action.payload,
                error:null,
                loading:false,
            }
        case OTP_FAILED:
            return {
                ...state,
                loading:false,
                data:null,
                error:action.payload
            } 
        default:
            return state       
    }
}

export const verifyOtpReducer = (
    state = {
        loading: false,
        data: null,
        error: null}, action) => {

        switch(action.type){

            case VERIFY_OTP_REQUEST: 
            return {
                ...state,
                loading: true,
                data:null,
                error:null
            }

            case VERIFY_OTP_SUCCESS:
                return {
                    ...state,
                    loading:false,
                    data:action.payload,
                    error:null
                }
            case VERIFY_OTP_FAILED:
                return {
                    ...state,
                    loading:false,
                    data:null,
                    error:action.payload
                }
            default:
                return state
        }
}

export const registerReducer = (
    state = {
        loading:false,
        userInfo:null,
        error:null,
    }, action) => {

        switch(action.type){

            case REGISTRATION_REQUEST:
                return{
                    ...state,
                    loading:true,
                    userInfo:null,
                    error:null
                }
            case REGISTRATION_SUCCESS:
                return{
                    ...state,
                    loading:false,
                    userInfo:action.payload,
                    error:null
                }
            case REGISTRATION_FAILED:
                return{
                    ...state,
                    loading:false,
                    userInfo:null,
                    error:action.payload
                }
            default: 
                 return state            
        }
}

export const userDetailsReducer = (
    state = {
        loading:false,
        data:null,
        error:null
    },action) => {

        switch(action.type){

            case USER_DETAILS_REQUEST:
                return{
                    ...state,
                    loading:true,
                    data:null,
                    error:null
                }
            case USER_DETAILS_SUCCESS:
                return{
                    ...state,
                    loading:false,
                    data:action.payload,
                    error:null
                }
            case USER_DETAILS_FAILED:
                return{
                    ...state,
                    loading:false,
                    data:null,
                    error:action.payload
                }
            default: return state            
        }

}

export const loginReducer = (
    state= {
        loading:false,
        userInfo:null,
        error:null
    },action)=>{

        switch(action.type){

            case LOGIN_REQUEST:
                return{
                    ...state,
                    loading:true,
                    userInfo:null,
                    error:null
                }
            case LOGIN_SUCCESS:
                return{
                    ...state,
                    loading:false,
                    userInfo:action.payload,
                    error:null
                }
            case LOGIN_FAILED:
                return{
                    ...state,
                    loading:false,
                    userInfo:null,
                    error:action.payload
                }
            case USER_LOGOUT:
                return {
                    userInfo: null,
                    error: null
                }
            default: return state            
        }


}


